import React, { useEffect } from 'react'
import { EditOutlined, DeleteForeverRounded, PictureAsPdfRounded } from '@mui/icons-material'

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import ConfirmationModal from 'components/Card/Confirmation'

import EditKPI from './form'
import CreateKPI from './form'

import KIPHOC from './actions'
import { badgeStatus } from 'utils/stageStatus'
import { getTranslation } from 'utils/renderLanguage'

const columnData = ( language ) => [
  { header: getTranslation( language, 'No.' ), accessor: 'index_id', containerStyle: { width: '30%' } },
  { header: getTranslation( language, 'Key Performance Indicator(KPI)' ), accessor: 'kpi_title', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Description' ), accessor: 'kpi_description', containerStyle: { width: '150%' },
    renderColumn: rowData => <p style={{ textAlign: 'justify' }}>{ rowData.kpi_description }</p>
  },
  { header: getTranslation( language, 'Support Document' ), containerStyle: { width: '50%' },
    renderColumn: rowData => {
      if( rowData.kpi_document_url ){
        return(
          <button className='btn' onClick={ () => window.open( rowData.kpi_document_url, '_blank' ) }>
            <PictureAsPdfRounded style={{ color: '#cb1515' }}/>
          </button>
        )
      } else {
        return '-'
      }
    }
  },
  { header: getTranslation( language, 'Submission Date' ), accessor: 'kpi_submission_date', containerStyle: { width: '50%' } },
  { header: getTranslation( language, 'Notes' ), accessor: 'kpi_submission_note', containerStyle: { width: '80%' } },
  { header: getTranslation( language, 'Status' ), containerStyle: { width: '50%' },
    renderColumn: rowData => <p className='badge__status' style={ badgeStatus( rowData.kpi_status ) }>{ rowData.kpi_status }</p>
  },
]

const KPI = props => {

  useEffect( () => {
    props.getKPIs( props.projectDetail.kpi_indication )
  }, [ props.projectDetail ])

  return(
    <>
      <div className='mx-4'>

        <div className={ 'd-flex align-items-end mb-2' }>
          <h2 style={{ fontSize: 20, fontWeight: 500 }}>{ getTranslation( props.language, 'Project KPI' ) }</h2>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 'auto' }}
            onClick={ () => props.onChangeKPIHOC( true, 'showCreateModal' ) }>
            { getTranslation( props.language, 'Create KPI' ) }
          </button>
        </div>
          <PaginatedTable
            rowData={ props.kpi }
            columnData={ columnData( props.language ) }
            actionsColumn={[{
              name: 'Approve',
              className: 'btn btn-success',
              onShow: rowData => rowData.kpi_status === 'In Review',
              onClick: rowData => Promise.all([
                props.onChangeKPIHOC( rowData, 'selectedKPI' )
              ]).then( () => {
                props.onChangeKPIHOC( true, 'showApproveConfirmation' )
              })
            }, {
              name: 'Edit',
              onShow: rowData => rowData.kpi_status === 'Pending',
              renderActionIcon: <EditOutlined />,
              onClick: rowData => Promise.all([
                props.onChangeKPIHOC( rowData, 'selectedKPI' )
              ]).then( () => {
                props.onChangeKPIHOC( true, 'showEditModal' )
              })
            }, {
              name: 'Delete',
              style: { background: '#cb1515' },
              onShow: rowData => rowData.kpi_status === 'Pending',
              renderActionIcon: <DeleteForeverRounded />,
              onClick: rowData => Promise.all([
                props.onChangeKPIHOC( rowData, 'selectedKPI' )
              ]).then( () => {
                props.onChangeKPIHOC( true, 'showDeleteConfirmation' )
              })
            }]}
          />
      </div>
      {
        props.showCreateModal && <CreateKPI
          mode={ 'create' }
          title={ getTranslation( props.language, 'Create New KPI' ) }
          language={ props.language }
          projectDetail={ props.projectDetail }
          createNewKPI={ props.createNewKPI }
          setOpen={ val => props.onChangeKPIHOC( val, 'showCreateModal' ) }  
        />
      }
      {
        props.showEditModal && <EditKPI
          mode={ 'update' }
          title={ getTranslation( props.language, 'Edit KPI' ) }
          language={ props.language }
          updateKPI={ props.updateKPI }
          selectedKPI={ props.selectedKPI }
          projectDetail={ props.projectDetail }
          setOpen={ val => props.onChangeKPIHOC( val, 'showEditModal' ) }  
        />
      }
      {
        props.showApproveConfirmation && <ConfirmationModal
          isOpen={ true }
          onClick={ () => props.updateKPI({ ...props.selectedKPI, kpi_status: 'Complete' }) }
          confirmationText={
            <>
              You are going to approve the selected kpi from the project?
              <br/>
              Make sure that you have reviewed the submitted document.
              <br/><br/>
              <b className='text-danger'>This action is non-reversible.</b>
            </>
          }
          setOpen={ val => props.onChangeKPIHOC( val, 'showApproveConfirmation' ) }
        />
      }
      {
        props.showDeleteConfirmation && <ConfirmationModal
          isOpen={ true }
          onClick={ () => props.deleteKPI( props.selectedKPI.kpi_id ) }
          confirmationText={
            <>
              { getTranslation( props.language, 'You are going to remove the selected kpi from the project?' ) }
              <br/><br/>
              <b className='text-danger'>{ getTranslation( props.language, 'This action is non-reversible.' ) }</b>
            </>
          }
          setOpen={ val => props.onChangeKPIHOC( val, 'showDeleteConfirmation' ) }
        />
      }
      { props.onLoadKPI && <LoadingModal /> }
    </>
  )
}

export default KIPHOC( KPI )