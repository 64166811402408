import React from 'react'
import './navbar.scss'
import { Link } from 'react-router-dom'
import { SuperAdminItem,AdminItem, AssessorItem, LeaderItem, SecretariatItem } from './SidebarItem';

const Navbar = ({
  user,
  sidebar,
  language
}) => {

  const renderSideItem = () => {
    switch( user.role ){
      case 'Super Admin':
        return SuperAdminItem( language );
      case 'Admin':
        return AdminItem;
      case 'Assessor':
        return AssessorItem;
      case 'Project Leader':
        return LeaderItem;
      case 'Secretariat':
        return SecretariatItem;
    }
  }

  const renderActiveItem = path => {
    if( window.location.href.includes( path ) )
      return 'item-bars-active'
    else 
      return ''
  }

  return (
    <nav className={sidebar ? 'nav-menu' : 'nav-menu-expand'} style={{ paddingTop: 80 }}>
      <ul className='nav-menu-item'>
        {
          renderSideItem().map((item, index) => {
            return (
              <li key={index} className={ `item-bars ${ renderActiveItem( item.path ) }` } >
                <Link to={ `/dashboard${ item.path }` } className={sidebar?'nav-text nav-text-collapse':'nav-text nav-text-expand'} >
                  <span style={{ padding:"5px" }}>{item.icon}</span>
                  <span style={{ fontSize: 16 }} className={sidebar ? 'nav-item-text' : 'nav-item-text-expand'} > {item.title} </span>
                </Link>
              </li>
            )
          })
        } 
        </ul>
    </nav >
  )
}

export default Navbar