import _ from 'lodash'
import Moment from 'moment'
import localStorage from 'localStorage'
import React, { useEffect, useState } from 'react'
import { 
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import { Form } from "react-bootstrap"
import DatePicker  from 'react-datepicker'
import { FaFilePdf } from 'react-icons/fa'
import { useParams, useOutletContext } from 'react-router-dom';
import { Plagiarism, NavigateBefore, Clear } from '@mui/icons-material';

import LoadingModal from 'components/LoadingModal'
import SubmitConfirmation from 'components/Card/Confirmation'
import PreviewModal from '../../components/preview'

import { getTranslation } from 'utils/renderLanguage'
import AssessmentHOC from './actions'
import {
  projectTeam,
  gradeOptions,
  scoringOptions,
  projectReporting,
  assessmentCretiria,
  projectAchievement,
  communityBeneficiary,
  collaborativePartners,
} from '../assets'
import '../index.scss'

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const language = localStorage.getItem( 'english' )

const fieldData = [
  { label: 'Assessor Name', value: 'assessor_name', disabled: true },
  { label: 'Date', value: 'summission_date', disabled: true },
  { label: 'Total Score', value: 'jumlah_markah' },
  { label: 'Assessment', value: 'pernilaian', type: 'textarea' },
  { 
    label: 'Based on your assessment, kindly provide your comment as follow:',
    children: [
      { label: 'Things/Achievements can be proud of. (Commendable Areas)', value: 'comment', type: 'textarea' },
      { label: 'Things to be fixed. (Affirmative Areas)', value: 'affirmative', type: 'textarea' },
      { label: 'Other Remarks', value: 'remark', type: 'textarea' },
      { label: 'Overall Grade', value: 'overall_grade', type: 'radio', options: gradeOptions }
    ]
  },
]

const ViewAssessment = props => {
  
  const outletContext = useOutletContext()
  const { 
    project_id: selectedProjectId,
    assessment_id
  } = useParams()

  const [ numPages, setNumPages ] = useState( null )
  const [ pageNumber, setPageNumber ] = useState( 1 )
  const [ selectedCriteria, setSelectedCriteria ] = useState( 1 )

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  
  useEffect( () => {
    Promise.all([
      props.getProjectDetail( selectedProjectId )
    ]).then( () => {
      props.getAssessment( assessment_id )
    })
  }, [])

  useEffect( () => {
    if( selectedCriteria === 6 ){
      let tempScore = 0
      let temp = _.cloneDeep( props.assessment )
      _.map( [1,2,3,4,5], index => {
        Object.keys( props.assessment[index] ).map( key => {
          tempScore += Number( props.assessment[index][key] || 0 )
        })
      })
      temp[6]={
        ...props.assessment[ 6 ],
        assessor_name: outletContext.user.display_name,
        summission_date: Moment().format( 'DD MMM YYYY' ),
        jumlah_markah: ( tempScore / 29 * 20 ).toFixed( 2 )
      }
      props.onChangeProjectAssessmentHOC( temp, 'assessment' )
    }
  }, [ selectedCriteria ])

  const onChangeResult = ( assessment_id, value ) => {
    let temp = _.cloneDeep( props.assessment )
    temp[ selectedCriteria ][ assessment_id ] = value
    props.onChangeProjectAssessmentHOC( temp, 'assessment' )
  }

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( props.assessment )
    temp[ 6 ][ context ] = val
    props.onChangeProjectAssessmentHOC( temp, 'assessment' )
  }

  const renderFieldInput = ( item, index ) => {
    if( item.type === 'textarea' ) {
      return(
        <Form.Group key={ index } className='mb-3 col-lg-12'>
          <Form.Label>{ getTranslation( language, item.label ) }</Form.Label>
          <Form.Control
            as='textarea'
            type={ 'text' }
            disabled={ props.assessment.updated_at }
            style={{ minHeight: 150 }}
            placeholder={ getTranslation( language, item.label ) }
            value={ props.assessment[ 6 ][ item.value ] }
            onChange={ e => onChangeField( e.target.value, item.value ) }
          />
        </Form.Group>
      )
    } else if ( item.type === 'radio' ) {
      return (
        <Form.Group key={ index } className='mb-3 col-lg-12'>
          <Form.Label>{ getTranslation( language, item.label ) }</Form.Label>
          <RadioGroup
            row
            value={ props.assessment[ 6 ][ item.value ] || '' }
            name={ `assessment-6-${ item.id }` }
            onChange={ e => onChangeField( e.target.value, item.value ) }>
            {
              item.options.map(( option, index ) => {
                return(
                  <FormControlLabel disabled={ props.assessment.updated_at } classes={{ label: 'radio-label' }} key={ index } value={ option.id } control={ <Radio size="small" /> } label={ getTranslation( language, option.label ) } />
                )
              })
            }
          </RadioGroup>
        </Form.Group>
      )
    } else {
      return(
        <Form.Group key={ index } className='mb-3 col-lg-6'>
          <Form.Label>{ getTranslation( language, item.label ) }</Form.Label>
          <Form.Control
            type={ item.type }
            placeholder={ getTranslation( language, item.label ) }
            disabled={ item.disabled || props.assessment.updated_at }
            maxLength={ item.type === 'number' ? 3 : '' }
            value={ props.assessment[ 6 ][ item.value ] }
            onChange={ e => {
              let val = e.target.value
              if( item.type !== 'number' || ( item.type === 'number' && val <= 100 ) ){
                onChangeField( val, item.value ) 
              }
            }}
          />
        </Form.Group>
      )
    }
  }
  
  const renderCriteriaColumn = () => {
    let temp = []
    switch( selectedCriteria ){
      case 1:
        temp = projectReporting
        break;
      case 2:
        temp = projectAchievement
        break;
      case 3:
        temp = projectTeam
        break;
      case 4:
        temp = collaborativePartners
        break;
      case 5:
        temp = communityBeneficiary
        break;
    }

    return(
      <>
        <label className='title'>{ _.find( assessmentCretiria, { id: selectedCriteria } ).label }</label>
        <div className='row'>
          {
            ( selectedCriteria < 6 && temp !== 'overall' && temp.length > 0 ) && temp.map(( item, index ) => {
              return(
                <div key={ index } className={ 'col-lg-4 col-md-6 mb-2' } style={ props.selectedFile ? { width: '100%' } : {}}>
                  <div className={ 'assessment-card' }>
                    <label>{ item.label }</label>
                    <RadioGroup
                      row
                      value={ props.assessment[ selectedCriteria ][ item.id ] || '' }
                      name={ `assessment-${ selectedCriteria }-${ item.id }` }
                      onChange={ event => onChangeResult( item.id, event.target.value ) }>
                      {
                        scoringOptions.map(( option, index ) => {
                          return(
                            <FormControlLabel
                              key={ index }
                              value={ option.id }
                              classes={{ label: 'radio-label' }}
                              disabled={ props.assessment.updated_at || outletContext.user.role !== 'Assessor' }
                              control={ <Radio size="small" /> }
                              label={ getTranslation( language, option.label ) } />
                          )
                        })
                      }
                    </RadioGroup>
                  </div>
                </div>
              )
            })
          }
          {
            selectedCriteria === 6 && (
              <>
                {
                  fieldData.map(( item, index ) => (
                    <>
                      {
                        item.children ? (
                          <div className='col-lg-12'>
                            <div className='project-assessment__overall-card'>
                              <p style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>{ getTranslation( language, item.label ) }</p>
                              <div className='row'>
                                {
                                  item.children.map( child => (
                                    renderFieldInput( child, index )
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        ) : ( renderFieldInput( item, index ) )
                      }
                    </>
                  ))
                }
                <Form.Group className='mt-4 mb-5'>
                  <Form.Check
                    type={ 'checkbox' }
                    style={{ fontWeight: 500 }}
                    checked={ props.assessment[6].akuan }
                    disabled={ props.assessment.updated_at }
                    onChange={ e => onChangeField( !props.assessment[6].akuan, 'akuan' ) }
                    label={
                      <>
                        { getTranslation( language, 'I confirm the assessment was made.' ) }<br/>
                { getTranslation( language, 'I hereby confirm the evaluation confirm the quotation documents and have examined all the documents submitted.' ) }
                      </>
                    } />
                </Form.Group>
              </>
            )
          }
        </div>
        <button
          className='btn btn-success'
          style={{ float: 'right', marging: '10px 0px', width: 100 }}
          disabled={ selectedCriteria < 6 ? Object.keys( props.assessment[ selectedCriteria ] ).length < temp.length : ( !props.assessment[6].akuan || props.assessment.updated_at ) }
          onClick={ () => {
            if( selectedCriteria < 5 ){
              setSelectedCriteria( selectedCriteria + 1 )
            } else if( selectedCriteria === 5 ) {
              setSelectedCriteria( selectedCriteria + 1 )
              props.onChangeProjectAssessmentHOC( '', 'selectedFile' )
            }else {
              props.onChangeProjectAssessmentHOC( true, 'showSubmitConfirmation' )
            }
          }}>
          { selectedCriteria < 6 ? 'Next' : 'Submit' }
        </button>
      </>
    )
  }
  
  return(
    <>
      <div className="d-flex align-items-center mb-5">
        <button className="back-button" onClick={ () => outletContext.onNavigate( -1 ) }>
          <NavigateBefore/>
        </button>
        <h4>
          { getTranslation( language, 'Project Assessment' ) }
          <span style={{ fontSize: 14, marginLeft: 5 }}>/ { selectedProjectId }</span>
        </h4>
      </div>
      <div className='d-flex justify-content-between mb-4'>
        <div className='year-selection'>
          <label>{ getTranslation( language, 'Year' ) }</label>
          <DatePicker
            className='form-date-picker'
            disabled={ true }
            selected={ props.selectedYear }
            onChange={ date => props.onChangeProjectAssessmentHOC( date, 'selectedYear' ) }
            dateFormat="QQQ, yyyy"
            showQuarterYearPicker
          />
        </div>
        <button
          className='btn btn-primary mt-auto'
          onClick={ () => props.onChangeProjectAssessmentHOC( true, 'showPreviewModal' ) }>
          <Plagiarism /> { getTranslation( language, 'Preview' ) }
        </button>
      </div>
      <div className='d-flex justify-content-between mt-2 mb-2'>
        {
          assessmentCretiria.map(( item, index ) => {
            return(
              <button
                key={ index }
                className={  'project-assessment__progres-btn' }
                onClick={ () => {
                  if( item.id === 1 || !_.isEmpty( props.assessment[ item.id - 1 ] ) ){
                    setSelectedCriteria( item.id ) 
                  }
                }}>
                <p className={ `${ item.id === selectedCriteria && 'selected' }` }>{ item.id }</p>
                { getTranslation( language, item.label ) }
              </button>
            )
          })
        }
      </div>
      <div className='row mt-4 mx-1'>
        {
          props.selectedFile && (
            <div className='col-lg-8'>
              <div className='react-pdf-viewer__filename'>
                <FaFilePdf />{ props.selectedFilename || 'No file name' }
                <Clear style={{ cursor: 'pointer', marginLeft: 'auto', color: 'grey' }} onClick={ () => props.onChangeProjectAssessmentHOC( '', 'selectedFile' ) } />
              </div>
              <Document file={ props.selectedFile } onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={ pageNumber } />
              </Document>
              <div className='react-pdf-viewer__navigation'>
                <button
                  style={{ width: 100 }}
                  className='btn btn-outline-secondary mr-2'
                  disabled={ pageNumber === 1 }
                  onClick={ () => setPageNumber( pageNumber - 1 ) }>
                  { getTranslation( language, 'Previous' ) }
                </button>
                <b className='m-2'>{ pageNumber }</b> / <b className='m-2'>{ numPages }</b>
                <button
                  style={{ width: 100 }}
                  className='btn btn-outline-secondary ml-2'
                  disabled={ pageNumber === numPages }
                  onClick={ () => setPageNumber( pageNumber + 1 ) }>
                  { getTranslation( language, 'Next' ) }
                </button>
              </div>
            </div>
          )
        }
        <div className={ `${ props.selectedFile ? 'col-lg-4' : 'col-lg-12' } project-assessment__assessment-column` }>
          { renderCriteriaColumn() }
        </div>
      </div>
      {
        props.showSubmitConfirmation && <SubmitConfirmation
          isOpen={ true }
          onClick={ () => props.submitAssessment( outletContext.onNavigate ) }
          confirmationText={
            <>
              Are you sure to submit this Project Assessment Report to the project?
              <br/><br/>
              <b className='text-danger'>This action is non-reversible.</b>
            </>
          }
          setOpen={ val => props.onChangeProjectAssessmentHOC( val, 'showSubmitConfirmation' ) }
        />
      }
      {
        props.showPreviewModal && <PreviewModal
          kpi={ props.kpi }
          projectDetail={ props.projectDetail }
          activity_reports={ props.activity_reports }
          summary_year_options={ props.summary_year_options }
          activity_reports_summary={ props.activity_reports_summary }
          onLoadProjectAssessment={ props.onLoadProjectAssessment }

          getKPIs={ props.getKPIs }
          getActivityReports={ props.getActivityReports }
          onChangeHOC={ props.onChangeProjectAssessmentHOC }
          setOpen={ val => props.onChangeProjectAssessmentHOC( val, 'showPreviewModal' ) }  
        />
      }
      {
        props.onLoadProjectAssessment && <LoadingModal />
      }
    </>
  )
}

export default AssessmentHOC( ViewAssessment );