import React from 'react'

import './index.scss'

const InfoCard = ({
  cardList,
  dataValue,
  contentClass,
  contentStyle,
  containerStyle,
  containerClass,
}) => {

  return(
    <div 
    style={{ ...containerStyle }}
      className={ `info-card__cont ${ containerClass || '' }` }
    >
      {
        ( cardList && cardList.length > 0 ) && cardList.map(( item, index ) => {
          return(
            <div
              key={ index }
              style={{ ...contentStyle }}
              className={ `info-card__detail-card ${ contentClass || '' }` }
            >
              <p className='info-card__detail-title'>{ item.label }</p>
              <p className='info-card__detail-value'>{ dataValue[ item.value ] }</p>
            </div>
          )
        })
      }
    </div>
  )
}

export default InfoCard;