import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import localStorage from 'localStorage'
import { ImFilePdf } from 'react-icons/im'
import { Form, Modal, Button } from "react-bootstrap"
import { DeleteForeverRounded } from '@mui/icons-material'

import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )
const fieldData = [
  { label: 'Date', value: 'kpi_submission_date', type: 'date' },
  { label: 'Support Document', value: 'kpi_document', type: 'file' },
  { label: 'Notes', value: 'kpi_submission_note', type: 'textarea' },
]

const SubmissionForm = props => {

  const [ kpi, setKpi ] = useState({
    kpi_title: '',
    kpi_description: '',
    kpi_document: '',
    kpi_submission_date: Moment().format( 'DD MMM YYYY' ),
    kpi_submission_note: '',
    kpi_status: 'On-Progress',
    project_id: props.projectDetail.project_id,
  })
  const [error, setError] = useState({})

  useEffect( () => {
    if( props.selectedKPI ){
      setKpi({ 
        ...props.selectedKPI,
        kpi_status: 'On-Progress',
        kpi_submission_date: Moment().format( 'DD MMM YYYY' ),
        kpi_document: props.selectedKPI.kpi_document_file_name ? {
          snapshot_file_name: props.selectedKPI.kpi_document_file_name
        } : '',
        kpi_id: props.selectedKPI.kpi_id,
        kpi_title: props.selectedKPI.kpi_title,
        kpi_description: props.selectedKPI.kpi_description,
      })
    }
  }, [] )

  const handleSubmit = ( status ) => Promise.all([
    fieldData.map( item => {
      if( kpi[ item.value ] === '' ){
        setError({ [ item.value ]: `${ getTranslation( language, item.label ) } should not left empty.` })
      }
    })
  ]).then( () => {
    if( !_.values( kpi ).some( x => !x || x === '' ) ){
      props.putKPISubmission({ ...kpi, kpi_status: status })
    }
  })

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( kpi )
    temp[ context ] = val
    setKpi( temp )
  }

  const onUploadFile = ( event, context ) => {
    if( event.target.files && event.target.files.length > 0 ) {
      let files = event.target.files
      Object.keys( event.target.files ).map((key) => {
        let reader = new FileReader()
        reader.onload = (e) => {
          let tmp = {
            snapshot: e.target.result,
            snapshot_file_name: files[key] ? files[key].name : 'kpi_support_document',
          }
          onChangeField( tmp, context )
        }
        reader.readAsDataURL(event.target.files[key])
      })
    }
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body className='row'>
        <div className='col-12'>
          <div className='project-detail__detail-cont'>
            <label>{ getTranslation( language, 'Key Performance Indicator(KPI)' ) }</label>
            <p>{ kpi.kpi_title }</p>
            <label className='mt-2'>{ getTranslation( language, 'Description' ) }</label>
            <p>{ kpi.kpi_description }</p>
          </div>
        </div>
        <div className='col-12 px-4'>
          {
            fieldData.map(( item, index ) => {
              if( item.type === 'file' ){
                return(
                  <Form.Group key={ index } className='mt-3 col-lg-6' style={{ marginRight: 5 }}>
                    <Form.Label>{ getTranslation( language, item.label ) }</Form.Label>
                    {
                      kpi[ item.value ] && (
                        <div className='project-detail__detail-cont d-flex align-items-center' style={{ padding: '10px 20px' }}>
                          <ImFilePdf style={{ width: 40, height: 40, color: 'red' }}/>
                          <div style={{ marginLeft: 10 }}>
                            <label>File Name</label>
                            <p className='m-0' >
                              { kpi[ item.value ].snapshot_file_name }
                            </p>
                          </div>
                          <button
                            className={ 'btn btn-danger ml-auto' }
                            onClick={ () =>  onChangeField( '', 'kpi_document' ) }
                          >
                            <DeleteForeverRounded />
                          </button>
                        </div>
                      )
                    }
                    {
                      !kpi[ item.value ] && (
                        <Form.Control
                          type={ 'file' }
                          style={{ height: 'fit-content', fontSize: 18 }}
                          accept={ 'application/pdf' }
                          onChange={ event => onUploadFile( event, item.value ) }
                        />
                      )
                    }
                    { error[ item.value ] && <Form.Text>{ error[ item.value ] }</Form.Text>}
                  </Form.Group>
                )
              } else if( item.type === 'textarea' ) {
                return(
                  <Form.Group key={ index } className='mt-3 col-lg-12'>
                      <Form.Label>{ getTranslation( language, item.label ) }</Form.Label>
                      <Form.Control
                        as='textarea'
                        type={ 'text' }
                        style={{ minHeight: 200 }}
                        placeholder={ getTranslation( language, item.label ) }
                        disabled={ item.disabled }
                        value={ kpi[ item.value ] }
                        onChange={ e => onChangeField( e.target.value, item.value ) }
                      />
                      { error[ item.value ] && <Form.Text>{ error[ item.value ] }</Form.Text>}
                    </Form.Group>
                )
              } else {
                return(
                  <Form.Group key={ index } className='mt-3 col-lg-6'>
                    <Form.Label>{ getTranslation( language, item.label ) }</Form.Label>
                    <Form.Control
                      type={ item.type }
                      placeholder={ getTranslation( language, item.label ) }
                      disabled={ item.disabled }
                      value={ kpi[ item.value ] }
                      onChange={ e => onChangeField( e.target.value, item.value ) }
                    />
                    { error[ item.value ] && <Form.Text>{ error[ item.value ] }</Form.Text>}
                  </Form.Group>
                )
              }
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
        { getTranslation( language, 'Close' ) }
      </Button>
      <Button
        variant="secondary"
        onClick={ () => handleSubmit( 'On-Progress') }>
        { getTranslation( language, 'Draft' ) }
      </Button>
      <Button
        variant="primary"
        onClick={ () => handleSubmit( 'In Review') }>
        { getTranslation( language, 'Submit' ) }
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default SubmissionForm;