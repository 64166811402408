import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import localStorage from 'localStorage'
import { ImFilePdf } from 'react-icons/im'
import { Form, Modal, Button } from "react-bootstrap"
import { DeleteForeverRounded } from '@mui/icons-material'

import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )
const fieldData = [
  { label: getTranslation( language, 'Utilization(RM)' ), value: 'utilization_amount', type: 'number' },
  { label: getTranslation( language, 'Utilization(%)' ), value: 'utilization_percentage', type: 'number' },
  { label: getTranslation( language, 'Project Completion(%)' ), value: 'project_completion', type: 'number' },
  { label: getTranslation( language, 'Support Document' ), value: 'utilization_file', type: 'file' },
]

const UtilizationForm = props => {

  const [ utilization, setUtilization ] = useState({
    utilization_amount: '',
    utilization_percentage: '',
    project_completion: '',
    utilization_file: '',
    utilication_date: Moment().format( 'DD MMM YYYY' ),
    project_id: props.projectDetail.project_id,
  })

  useEffect( () => {
    if( props.selectedUtilization && props.mode === 'update' ){
      setUtilization( props.selectedUtilization )
    }
  }, [] )

  const submitDisable = () => {
    if( props.mode === 'create' ){
      return !utilization.utilization_file
    } else {
      return !utilization.utilization_file_url && !utilization.utilization_file
    }
  }

  const handleSubmit = () => {
    if( props.mode === 'create' )
      props.createUtilization( utilization )
    else 
      props.updateUtilization( utilization )
  }

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( utilization )
    temp[ context ] = val
    setUtilization( temp )
  }

  const onUploadFile = ( event, context ) => {
    if( event.target.files && event.target.files.length > 0 ) {
      let files = event.target.files
      Object.keys( event.target.files ).map((key) => {
        let reader = new FileReader()
        reader.onload = (e) => {
          let tmp = {
            snapshot: e.target.result,
            snapshot_file_name: files[key] ? files[key].name : 'utilization',
          }
          onChangeField( tmp, context )
        }
        reader.readAsDataURL(event.target.files[key])
      })
    }
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body className='row'>
        {
          fieldData.map(( item, index ) => {
            if( item.type === 'file' ){
              return(
                <Form.Group key={ index } className='mt-3 col-lg-6' style={{ marginRight: 5 }}>
                  <Form.Label>{ item.label }</Form.Label>
                  {
                    ( utilization.utilization_file_url || utilization[ item.value ] ) && (
                      <div className='project-detail__detail-cont d-flex align-items-center' style={{ padding: '10px 20px' }}>
                        <ImFilePdf style={{ width: 40, height: 40, color: 'red' }}/>
                        <div style={{ marginLeft: 10 }}>
                          <label>{ getTranslation( language, 'File Name' ) }</label>
                          <p className='m-0' >
                            { 
                              utilization.utilization_file_url
                                ? utilization.utilization_file_name
                                : utilization[ item.value ].snapshot_file_name
                            }
                          </p>
                        </div>
                        <button
                          className={ 'btn btn-danger ml-auto' }
                          onClick={ () => {
                            if( utilization.utilization_file_url ){
                              onChangeField( '', 'utilization_file_url' )
                            } else {
                              onChangeField( '', 'utilization_file' )
                            }
                          }}
                        >
                          <DeleteForeverRounded />
                        </button>
                      </div>
                    )
                  }
                  {
                    ( !utilization.utilization_file_url && !utilization[ item.value ] ) && (
                      <Form.Control
                        type={ 'file' }
                        style={{ height: 'fit-content' }}
                        accept={ 'application/pdf' }
                        onChange={ event => onUploadFile( event, item.value ) }
                      />
                    )
                  }
                </Form.Group>
              )
            } else {
              return(
                <Form.Group key={ index } className='mt-3 col-lg-6'>
                  <Form.Label>{ item.label }</Form.Label>
                  <Form.Control
                    type={ item.type }
                    placeholder={ item.label }
                    disabled={ item.disabled }
                    value={ utilization[ item.value ] }
                    onChange={ e => onChangeField( e.target.value, item.value ) }
                  />
                </Form.Group>
              )
            }
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
          { getTranslation( language, 'Close' ) }
        </Button>
        <Button
          variant="primary"
          disabled={ submitDisable() } 
          onClick={ handleSubmit } >
          { getTranslation( language, 'Submit' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UtilizationForm;