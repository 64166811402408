import React from 'react';
import _ from 'lodash'
import Moment from 'moment'
import localStorage from 'localStorage'
import { Tooltip } from '@mui/material'

import { renderBackground } from 'utils/stageStatus'
import { getTranslation } from 'utils/renderLanguage'
import { milestoneFieldData as milestoneFieldList } from 'assets/ProjectManagement';

const language = localStorage.getItem( 'english' )

export const milestoneFieldData = [
  { label: getTranslation( language, 'Milestone Title' ), value: 'milestone_title', type: 'text' },
  { label: getTranslation( language, 'KPI(%)' ), value: 'milstone_kpi', type: 'number' },
  { label: getTranslation( language, 'Start Date' ), value: 'milestone_start_date', type: 'date' },
  { label: getTranslation( language, 'End Date' ), value: 'milestone_end_date', type: 'date' },
  { label: getTranslation( language, 'Utilization Fund' ), value: 'milestone_fund_utilization', type: 'select' },
]

export const columnData = disbursementList => {
  let accumulated_kpi = 0

  return [
    { header: getTranslation( language, 'Stage' ), accessor: 'stage_name', containerStyle: { width: '60%', minWidth: 150, textAlign: 'justify' } },
    { header: getTranslation( language, 'Milestone' ), containerStyle: { width: '400%' }, 
      renderColumn: rowData => (
        <div className='w-100'>
          {
            ( rowData.stage_milestone && rowData.stage_milestone.length > 0 ) && (
              <>
                <div className='project-detail__milestone-item' style={{borderBottom: 'none'}}>
                  {
                    milestoneFieldList.map( milestoneField => (
                      <div className='project-detail__milestone-item-content' style={{ ...milestoneField.containerStyle }}>
                        <label style={{ fontWeight: 500, fontSize: 12, color: 'grey' }}>{ milestoneField.label }</label>
                      </div>
                    ))
                  }
                </div>
                {
                  rowData.stage_milestone.map(( milestone, index ) => {
                    accumulated_kpi = accumulated_kpi + Number( milestone.milstone_actual_kpi || 0 )

                    return (
                      <div key={ index } className='project-detail__milestone-item'>
                        {
                          milestoneFieldList.map( milestoneField => (
                            <div className='project-detail__milestone-item-content' style={{ ...milestoneField.containerStyle }}>
                              {
                                milestoneField.value === 'milestone_fund_utilization' &&
                                  <p style={{ fontWeight: 500, fontSize: 12 }}>
                                    {
                                      _.find( disbursementList, { value: milestone[ milestoneField.value ]  } )
                                        ? _.find( disbursementList, { value: milestone[ milestoneField.value ]  } ).label
                                        : '-' 
                                    }
                                  </p>
                              }
                              {
                                milestoneField.value === 'milestone_status' &&
                                  <Tooltip placement="top" title={ milestone[ milestoneField.value ] || '-' }>
                                    <p
                                      style={{ backgroundColor: renderBackground( milestone[ milestoneField.value ] ) }}
                                      className='project-detail__milestone-status'>
                                    </p>
                                  </Tooltip>
                              }
                              {
                                milestoneField.value.includes( 'date' ) &&
                                  <p style={{ fontWeight: 500, fontSize: 12 }}>{
                                    milestone[ milestoneField.value ]
                                    ? Moment( milestone[ milestoneField.value ] ).format( 'MMM YYYY' )
                                    : '-'
                                  }</p>
                              }
                              {
                                milestoneField.value === 'accumulated_kpi' &&
                                  <p style={{ fontWeight: 500, fontSize: 12 }}>{accumulated_kpi}%</p>
                              }
                              {
                                [ 'milestone_title', 'milstone_kpi', 'milstone_actual_kpi' ].includes( milestoneField.value ) &&
                                  <p style={{ fontWeight: 500, fontSize: 12, textAlign: milestoneField.value === 'milestone_title' ? 'justify' : 'center' }}>{ milestone[ milestoneField.value ] || '-' }</p>
                              }
                          </div>
                          ))
                        }
                      </div>
                  )})
                }
              </>
            )
          }
        </div>
    )},
  ]
}