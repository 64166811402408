import { scoringOptions } from "utils/siteAudit"

export const overallAssessmentField = [
  { label: 'Project Progress Analysis', value: 'progress',
    children: [
      { label: 'Total Score(%)', value: 'jumlah_markah', type: 'number' },
      { label: 'Assessment', value: 'pernilaian', type: 'textarea' },
      { label: '1. Things/Achievements you can be proud of. (Commendable Areas)', value: 'comment', type: 'textarea' },
      { label: '2. Things to fix. (Affirmative Areas)', value: 'affirmative', type: 'textarea' },
      { label: '3. Other Remarks', value: 'other', type: 'textarea' },
      { label: 'Overall Grade', value: 'overall_grade', type: 'select', options: scoringOptions }
    ]
  },
  { label: 'Analysis Site Audit', value: 'site_audit',
    children: [
      { label: '1. Physical achievement of the infrastructure reached 50%-75% of project progress as reported.', value: 'perkara_1', type: 'textarea' },
      { label: '2. Equipment and technology equipment is supplied and functions as specified asset and inventory records.', value: 'perkara_2', type: 'textarea' },
      { label: '3. Infrastructure and equipment are provided to be used and benefited by the community.', value: 'perkara_3', type: 'textarea' },
      { label: '4. The community gives feedback on project achievements and benefits received.', value: 'perkara_4', type: 'textarea' },
      { label: '5. Things to fix', value: 'perkara_5', type: 'textarea' },
    ]
  },
  { label: 'Recommendation', value: 'recommendation',
    children: [
      { label: 'Project Working Community (PWC)', value: 'pwc', type: 'textarea' },
      { label: 'Project Steering Community (PSC)', value: 'psc', type: 'textarea' },
    ]
  },
]