import React from 'react'

const CustomInput = ({
  className,
  style,
  containerClass,
  conatinerStyle,
  placeholder,
  onChange,
  type,
  value,
}) => {
  return(
    <div className={ `${ containerClass }` } style={{ width: '100%', ...conatinerStyle }}>
      <input
        className={ `${ className }` }
        style={{ ...style }}
        type={ type }
        placeholder={ placeholder }
        value={ value }
        onChange={ e => onChange( e.target.value )}
      />
    </div>
  )
}

export default CustomInput