export const roleOptions = [
  { label: 'Super Admin', value: 'Super Admin' },
  // { label: 'Admin', value: 'Admin' },
  { label: 'Assessor', value: 'Assessor' },
  { label: 'Project Leader', value: 'Project Leader' },
  { label: 'Admin/Secretariat', value: 'Secretariat' },
]
export const statusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
]
export const stateOptions = [
  { label: 'Perlis', value: 'Perlis' },
  { label: 'Kedah', value: 'Kedah' },
  { label: 'Pulau Pinang', value: 'Pulau Pinang' },
  { label: 'Perak', value: 'Perak' },
  { label: 'Terengganu', value: 'Terengganu' },
  { label: 'Kelantan', value: 'Kelantan' },
  { label: 'Pahang', value: 'Pahang' },
  { label: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { label: 'Selangor', value: 'Selangor' },
  { label: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { label: 'Melaka', value: 'Melaka' },
  { label: 'Johor', value: 'Johor' },
  { label: 'Sarawak', value: 'Sarawak' },
  { label: 'Sabah', value: 'Sabah' },
]
export const stageOptions = [
  { label: '-', value: '' },
  { label: 'Proposing', value: 'Proposing' },
  { label: 'Engagement', value: 'Engagement' },
  { label: 'Selection', value: 'Selection' },
  { label: 'Development', value: 'Development' },
  { label: 'Monitoring', value: 'Monitoring' },
  { label: 'Completed', value: 'Completed' }
]

export const monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const quaterlyList = [
  'Q1',
  'Q2',
  'Q3',
  'Q4'
]