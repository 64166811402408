import firebase from 'firebase/compat/app';

export const initializeFirebase = () => {
  if (!firebase.apps.length) {
     firebase.initializeApp({
      apiKey: "AIzaSyB9nm11oL_QeP4yRR5oVEjoQ9knjGOTpBA",
      authDomain: "myprestasi-f6daa.firebaseapp.com",
      projectId: "myprestasi-f6daa",
      storageBucket: "myprestasi-f6daa.appspot.com",
      messagingSenderId: "851718813229",
      appId: "1:851718813229:web:1fcec2a2a496951a60eaa4",
      measurementId: "G-X2D0R6XN55"
    })
  }
}