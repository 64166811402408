import React, { useEffect } from 'react'
import _ from 'lodash'
import { useNavigate } from "react-router-dom"
import localStorage from 'localStorage'

import CustomInput from "components/Input"
import CustomButton from "components/Button"
import LoadingModal from 'components/LoadingModal'
import LanguageToggle from 'components/Input/langguageToggle'

import { getTranslation } from 'utils/renderLanguage'
import Logo from 'assets/logo.png'
import LoginHOC from './action'
import './index.scss'

const LoginPage = ({
  onLoginLoad,
  recoverEmail,
  loginCredential,
  showForgetPassword,

  userLogin,
  recoverPassword,
  onChangeLoginHOC
}) => {

  const navigate = useNavigate()
  const language = localStorage.getItem( 'english' )

  useEffect( () => {
    const user = JSON.parse( localStorage.getItem( 'userLogin' ) )
    if( user !== null ){
      // localStorage.removeItem( 'userLogin' )
      setTimeout( () => {
        switch( user.role ){
          case 'Super Admin':
            return navigate( '/dashboard/admin/user-management' )
          case 'Admin':
            return navigate( '/dashboard/admin/project-management' )
          case 'Assessor':
            return navigate( '/dashboard/assessor/project-management' )
          case 'Project Leader':
            return navigate( '/dashboard/project-management' )
          case 'Secretariat':
            return navigate( '/dashboard/secretariat/project-management' )
          default:
            return navigate( '/dashboard' )
        }
      }, 1000 )
    }
  })

  const onChangeLogin = ( val, context ) => {
    let tmp = _.cloneDeep( loginCredential )
    tmp[ context ] = val
    onChangeLoginHOC( tmp, "loginCredential" )
  }

  return(
    <div className='at-login__background'>
      <LanguageToggle className={ 'at-login__toggle' }/>
      <div className='at-login__container'>
        <img src={ Logo } alt={ 'login-logo' } />
        {
          !showForgetPassword && (
            <>
              <CustomInput
                containerClass={ "mt-2 d-flex justify-content-center" }
                className={ "at-login__input" }
                placeholder={ getTranslation( language, 'Email' ) }
                value={ loginCredential.email || '' }
                type={ "text" }
                onChange={ val => onChangeLogin( val, "email" ) }
              />
              <CustomInput
                containerClass={ "mt-2 d-flex justify-content-center" }
                className={ "at-login__input" }
                placeholder={ getTranslation( language, 'Password' ) }
                value={ loginCredential.password || '' }
                type={ "password" }
                onChange={ val => onChangeLogin( val, "password" ) }
              />
              <CustomButton
                disabled={  _.values( loginCredential ).some( x => !x ) }
                containerClass={"mt-5 d-flex justify-content-center"}
                className={ "btn btn-primary at-login__login-button" }
                content={ getTranslation( language, 'Login' ) }
                onClick={ () => userLogin() }
              />
              <CustomButton
                containerClass={ "d-flex justify-content-center" }
                className={ "at-login__forget-password-button" }
                content={ getTranslation( language, 'Forget Password' ) }
                onClick={ () => onChangeLoginHOC( true, 'showForgetPassword' ) }
              />
            </>
          )
        }
        {
          showForgetPassword && (
            <>
              <CustomInput
                containerClass={ "mt-2 d-flex justify-content-center" }
                className={ "at-login__input" }
                placeholder="Email"
                value={ recoverEmail || '' }
                type={ "text" }
                onChange={ val => onChangeLoginHOC( val, 'recoverEmail' ) }
              />
              <CustomButton
                disabled={ !recoverEmail }
                containerClass={"mt-5 d-flex justify-content-center"}
                className={ "btn btn-primary at-login__login-button" }
                content={ "Recover Password" }
                onClick={() => recoverPassword() }
              />
            </>
          )
        }
      </div>
      { onLoginLoad && <LoadingModal /> }
    </div>
  )
}

export default LoginHOC( LoginPage );