import React, { useEffect } from 'react'
import localStorage from 'localStorage'
import { useOutletContext } from 'react-router-dom';
import { Info, Add } from '@mui/icons-material';

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'

import { getTranslation } from 'utils/renderLanguage'
import OverallAssessmentHOC from './actions'

const language = localStorage.getItem( 'english' )

const columnData = [
  { header: getTranslation( language, 'Date' ), accessor: 'date' },
  { header: getTranslation( language, 'Secretariat/Admin' ), accessor: 'name' },
  { header: getTranslation( language, 'Total Score' ), renderColumn: rowData => rowData.progress.jumlah_markah || '-' }
]

const OverallAssessment = props => {

  const outletContext = useOutletContext()
  const { uid, role } = outletContext.user

  useEffect( () => {
    if( role === 'Secretariat' ){
      props.getOverallAssessments( uid, props.projectDetail.project_id )
    } else {
      props.getAllOverallAssessment( props.projectDetail.project_id )
    }
  }, [ props.projectDetail ])

  return(
    <>
      <div className='d-flex justify-content-between mb-2'>
        <label className='at-project-mngmt__form-label'>
          { getTranslation( language, 'Overall Monitoring Analysis' ) }
        </label>
        {
          role === 'Secretariat' && (
            <button
              style={{ width: 300 }}
              disabled={ props.this_month_submitted }
              className='btn btn-primary'
              onClick={ () => outletContext.onNavigate( `/dashboard/secretariat/overall/${ props.projectDetail.project_id }` ) }>
              <Add style={{ marginRight: 5 }}/>{ getTranslation( language, 'Create Analysis' ) }
            </button>
          )
        }
      </div>
      <PaginatedTable
        rowData={ props.overallAssessmentList || [] }
        columnData={ columnData }
        actionsColumn={[{
          name: getTranslation( language, 'View' ),
          renderActionIcon: <Info />,
          onClick: rowData => outletContext.onNavigate( `/dashboard/overall/${ props.projectDetail.project_id }/${ rowData.overall_id }` )
        }]}
      />
      { props.onLoadSiteAssessment && <LoadingModal /> }
    </>
  )
}

export default OverallAssessmentHOC( OverallAssessment );