export const assessmentCretiria = [
  { id: 1, label: 'Assessment on Project Reporting' },
  { id: 2, label: 'Assessment on Project Achievement' },
  { id: 3, label: 'Assessment on Project Team' },
  { id: 4, label: 'Assessment on Collaboration Partners' },
  { id: 5, label: 'Assessment on Community Beneficiary' },
  { id: 6, label: 'Overall Comments & Other Remarks' },
]

export const projectReporting = [
  { id: 1, label: 'Pelaporan prestasi projek dikemaskini seperti ditetapkan' },
  { id: 2, label: 'Pelaporan aktiviti dikemaskini seperti ditetapkan' },
  { id: 3, label: 'Pelaporan Key Performance Indicator (KPI) dikemaskini seperti ditetapkan' },
  { id: 4, label: 'Pelaporan kewangan dikemaskini seperti ditetapkan' },
  { id: 5, label: 'Mengemukakan dan memuat naik dokumen sokongan' },
]

export const gradeOptions = [
  { id: 1, label: 'Unsatisfactory' },
  { id: 2, label: 'Satisfactory' },
  { id: 3, label: 'Good' },
  { id: 4, label: 'Very Good' },
  { id: 5, label: 'Excellent' },
]

export const scoringOptions = [
  { id: 1, label: 'Poor' },
  { id: 2, label: 'Below Target' },
  { id: 3, label: 'On Target' },
  { id: 4, label: 'Exceed Target' },
  { id: 5, label: 'Significantly Exceed Target' },
]

export const projectAchievement = [
  { id: 1, label: 'Objective Achievement' },
  { id: 2, label: 'Milestone Achievement' },
  { id: 3, label: 'Timeline of delivery (timing performance)' },
  { id: 4, label: 'Project financial performance' },
  { id: 5, label: 'Quantitative Project Output' },
  { id: 6, label: 'Quantitative Project Outcome' },
  { id: 7, label: 'Qualitative Project Output' },
  { id: 8, label: 'Qualitative Project Outcome' },
  { id: 9, label: 'Project sustainability' },
  { id: 10, label: 'Overall Assessment' },
]

export const projectTeam = [
  { id: 1, label: 'Level of Knowledge being transferred solved project problems' },
  { id: 2, label: 'Coaching and advices that has been given' },
  { id: 3, label: 'In depth knowledge on the project' },
  { id: 4, label: 'Commitment and ethics shown by team to the project' },
  { id: 5, label: 'Overall achievement by the team project' },
]

export const collaborativePartners = [
  { id: 1, label: 'Acceptance by the Collaborative Patners' },
  { id: 2, label: 'Understanding of the project' },
  { id: 3, label: 'Cooperation and commitment showed by Collaborative Patners' },
]

export const communityBeneficiary = [
  { id: 1, label: 'Acceptance by the Community Beneficiary' },
  { id: 2, label: 'Understanding of the project' },
  { id: 3, label: 'Cooperation and commitment showed by Community Beneficiary' },
  { id: 4, label: 'Projects result in practice change and empowered communities' },
  { id: 5, label: 'IMPACT4C system achievements' },
  { id: 6, label: 'Proceeds to third parties' },
]