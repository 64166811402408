import React, { Component } from 'react'
import { json2csv } from 'json-2-csv';

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const JSON2CSVOptions = {
  delimiter: ';',
  quote: '"',
  expandArrayObjects: true,
};

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      dataValue: {
        total_project: 0,
        active_project: 0,
        pending_project: 0,
        rejected_project: 0,
      },
      projectList: [],
      selectedProject: {},
      
      showEditModal: false,
      showDeleteModal: false,
      showCreateModal: false,
      showExportConfirmation: false,
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeProjectManagementHOC = ( val, context ) => this.setState({ [ context ]: val })

    createNewProject = dataToSubmit => Post(
      `/project-newProject`,
      dataToSubmit,
      this.createNewProjectSuccess,
      this.createNewProjectError,
      this.load
    )
    createNewProjectSuccess = payload => {
      this.setState({ showCreateModal: false })
      this.getAllProject()
      this.requestSuccess( 'New Project Created' )
    }
    createNewProjectError = error => this.requestError( error )

    getAllProject = () => Get(
      `/project-getProject`,
      this.getAllProjectSuccess,
      this.getAllProjectError,
      this.load
    )
    getAllProjectSuccess = payload => this.setState({
      projectList: payload.data.projects,
      dataValue: payload.data.summary
    })
    getAllProjectError = error => this.requestError( error )

    updateProject = dataToSubmit => Post(
      `/project-updateProject`,
      dataToSubmit,
      this.updateProjectSuccess,
      this.updateProjectError,
      this.load
    )
    updateProjectSuccess = payload => {
      this.setState({ showEditModal: false })
      this.getAllProject()
      this.requestSuccess( payload.data.message )
    }
    updateProjectError = error => this.requestError( error )

    deleteProject = id => Delete(
      `/project-deleteProject?project_id=${ id }`,
      this.deleteProjectSuccess,
      this.deleteProjectError,
      this.load
    )
    deleteProjectSuccess = payload => {
      this.setState({ showDeleteModal: false })
      this.getAllProject()
      this.requestSuccess( payload.data.message )
    }
    deleteProjectError = error => this.requestError( error )

    exportCSV = () => {
      let temp = []
      this.load( true )
      this.setState({ showExportConfirmation: false })
      if(this.state.projectList.length > 0){
        this.state.projectList.map((project) => {
          Get(
            `/project-getProject?project_id=${ project.id }`,
            payload => {
              temp.push(payload.data)
              if( temp.length === this.state.projectList.length ){
                this.load( false )
                const csv = json2csv(temp, JSON2CSVOptions) 
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", "project.csv");
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
              }
            },
            (error) => this.requestError( error ),
            () => {}
          )
        })
      } else {
        this.load( false )
        this.requestError( 'No data to export' )
      }
    }

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadProjectManagement={ this.state.loading }

            exportCSV={ this.exportCSV }
            getAllProject={ this.getAllProject }
            deleteProject={ this.deleteProject }
            updateProject={ this.updateProject }
            createNewProject={ this.createNewProject }
            onChangeProjectManagementHOC={ this.onChangeProjectManagementHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC