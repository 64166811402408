
import React from "react"
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from './container/LoginPage'
import Dashboard from './container/Dashboard';
import LandingPage from "./container/LandingPage";
import HomePage from './container/Dashboard/HomePage';
import ViewSiteAudit from "./container/Dashboard/Assessor/SiteAudit/View";
import ViewAssessment from "./container/Dashboard/Assessor/ProjectAssessment/View";
import ViewOverallAssessment from "./container/Dashboard/Assessor/OverallAnalysis/View"

import AdminUserManagement from './container/Dashboard/Admin/UserManagment';
import AdminProjectManagement from "./container/Dashboard/Admin/ProjectManagement";
import AdminProjectManagementForm from "./container/Dashboard/Admin/ProjectManagement/form";
import AdminProjectDetails from "./container/Dashboard/Admin/ProjectManagement/Details";

import ProjectManagement from "./container/Dashboard/ProjectManagement";
import ProjectDetails from "./container/Dashboard/ProjectManagement/Details";

import AssessorProjectManagement from "./container/Dashboard/Assessor/ProjectManagement";
import AssessorProjectDetails from "./container/Dashboard/Assessor/ProjectManagement/Details";

import SecretariatProjectAudit from './container/Dashboard/Assessor/SiteAudit/Create'
import SecretariatProjectOverallAssessment from './container/Dashboard/Assessor/OverallAnalysis/Create'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

  return (
    <HashRouter>
      <Routes>
        <Route exact path='/' element={ <LandingPage /> } />
        <Route exact path='/login' element={ <Login /> } />
        <Route exact path='dashboard' element={ <Dashboard /> } >
          {/* General */}
          <Route path="home" element={ <HomePage /> } />
          <Route path="site-audit/:project_id/:site_audit_id" element={ <ViewSiteAudit /> } />
          <Route path="overall/:project_id/:overall_id" element={ <ViewOverallAssessment /> } />
          <Route path="project-assessment/:project_id/:assessment_id" element={ <ViewAssessment /> } />

          {/* Admin / Super Admin */}
          <Route path="admin/user-management" element={ <AdminUserManagement /> } />
          <Route path="admin/project-management" element={ <AdminProjectManagement /> } />
          <Route path="admin/project-management/create" element={ <AdminProjectManagementForm /> } />
          <Route path="admin/project-management/update/:project_id" element={ <AdminProjectManagementForm /> } />
          <Route path="admin/project-management/:project_id" element={ <AdminProjectDetails /> } />

          {/* Assessor */}
          <Route path="assessor/project-management" element={ <AssessorProjectManagement /> } />
          <Route path="assessor/project-management/:project_id" element={ <AssessorProjectDetails /> } />

          {/* Project Leader */}
          <Route path="project-management" element={ <ProjectManagement /> } />
          <Route path="project-management/:project_id" element={ <ProjectDetails /> } />

          {/* Secretariat */}
          <Route path="secretariat/project-management" element={ <AssessorProjectManagement /> } />
          <Route path="secretariat/project-management/:project_id" element={ <AdminProjectDetails /> } />
          <Route path="secretariat/site_audit/:project_id" element={ <SecretariatProjectAudit /> } />
          <Route path="secretariat/overall/:project_id" element={ <SecretariatProjectOverallAssessment /> } />
          
        </Route>
        <Route path="*" element={ <Navigate replace to="/dashboard" /> } />
      </Routes>
    </HashRouter>
  );
}

export default App;
