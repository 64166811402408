import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import DatePicker  from 'react-datepicker'
import { Form, Modal, Button } from "react-bootstrap"
import { Select, MenuItem } from '@mui/material'
import { Add } from '@mui/icons-material'

import { getTranslation } from 'utils/renderLanguage'
import { milestoneFieldData } from './assets'

import "react-datepicker/dist/react-datepicker.css";

const StageMilestoneForm = props => {

  const { language, selectedStage } = props
  const [ stage, setStage ] = useState({
    ...selectedStage,
    stage_timeline: selectedStage.stage_timeline ? new Date( selectedStage.stage_timeline ) : ''
  })

  useEffect( () => {
    setStage({
      ...selectedStage,
      stage_timeline: selectedStage.stage_timeline ? new Date( selectedStage.stage_timeline ) : ''
    })
  }, [ selectedStage ])

  const handleSubmit = () => {
    let temp = _.cloneDeep( props.projectDetail )
    if( props.projectDetail.stage_and_milestone === props.selectedStageIndex ){
      temp.stage_and_milestone.push( stage )
    } else {
      temp.stage_and_milestone[ props.selectedStageIndex ] = stage
    }
    props.setProjectDetail( temp )
    props.setOpen(false)
  }

  const addNewMilestone = () => {
    let temp = _.cloneDeep( stage )
    temp.stage_milestone.push({
      milestone_title: '',
      milstone_kpi: '',
      milstone_actual_kpi: '',
      milestone_start_date: '',
      milestone_end_date: '',
      milestone_actual_start_date: '',
      milestone_actual_end_date: '',
      milestone_status: 'Not Started',
      milestone_fund_utilization: ''
    })
    setStage( temp )
  }

  const onChangeStage = ( val, context ) => {
    let temp = _.cloneDeep( stage )
    temp[ context ] = val
    setStage( temp )
  }

  const removeMilestone = index => {
    let temp = _.cloneDeep( stage )
    temp.stage_milestone.splice( index, 1 )
    setStage( temp )
  }

  const onChangeMilestone = ( val, milestoneIndex, context ) => {
    let temp = _.cloneDeep( stage )
    if( context === 'milstone_kpi' ){
      let total = 0
      temp.stage_milestone.map(( item, index )=> {
        if( item.milstone_kpi && Number( item.milstone_kpi ) > 0 && index !== milestoneIndex )
          total = total + Number( item.milstone_kpi )
      })
      total = total + Number( val ) 
      if( total <= 100 ){
        console.log( total )
        temp.stage_milestone[ milestoneIndex ][ context ] = val
      } else {
        console.log( total )
      }
    } else {
      temp.stage_milestone[ milestoneIndex ][ context ] = val
    }
    setStage( temp )
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ getTranslation( language, 'Edit Stage' ) }</Modal.Title>
      </Modal.Header>
      <Modal.Body className='row'>
        <Form.Group className='mt-3 col-lg-6'>
          <Form.Label style={{ fontWeight: 500 }}>{ getTranslation( language, 'Stage Title' ) }</Form.Label>
          <Form.Control
            type={ 'text' }
            placeholder={ getTranslation( language, 'Stage Title' ) }
            value={ stage.stage_name }
            onChange={ e => onChangeStage( e.target.value, 'stage_name' ) }
          />
        </Form.Group>
        <Form.Group className='mt-3 col-lg-6 '>
          <Form.Label style={{ fontWeight: 500 }}>{ getTranslation( language, 'Stage Timeline' ) }</Form.Label>
          <DatePicker 
            className='form-date-picker'
            showQuarterYearPicker
            dateFormat="QQQ, yyyy"
            selected={ stage.stage_timeline }
            onChange={ val => onChangeStage( val, 'stage_timeline' )}
          />
        </Form.Group>
        <Form.Group className='mt-3 col-lg-12'>
          <div className='d-flex justify-content-between mb-2'>
            <Form.Label className='at-project-mngmt__form-label'>{ getTranslation( language, 'Stage Milestone' ) }</Form.Label>
            <button
              style={{ width: 200 }}
              className={ 'btn btn-primary' }
              onClick={ () => addNewMilestone() }>
              <Add style={{ marginRight: 5 }}/>{ getTranslation( language, 'Add Milestone' ) }
            </button>
          </div>
          <div className={ 'at-project-mngmt__form-card overflow-auto' }>
            {
              ( stage.stage_milestone && stage.stage_milestone.length > 0 ) &&
              stage.stage_milestone.map(( milestone, milestoneIndex ) => {
                return(
                  <div key={ milestoneIndex } className='at-project-mngmt__milestone-cont'>
                    <p className='m-2'>{ milestoneIndex + 1 }.</p>
                    <div className='at-project-mngmt__milestone-cont mt-1'>
                      {
                        milestoneFieldData.map(( item, fieldIndex ) => (
                          <Form.Group key={ fieldIndex } style={{ width: 190 }}>
                            {
                              milestoneIndex === 0 && <Form.Label style={{ fontWeight: 500 }}>{ item.label }</Form.Label>
                            }
                            {
                              item.type === 'select' && (
                                <Select
                                  fullWidth
                                  value={ milestone[ item.value ] || [] }
                                  placeholder={ getTranslation( language, item.label ) }
                                  style={{ borderRadius: `0.375rem`, borderColor: '#ced4da', height: 50, width: 190 }}
                                  onChange={ e => onChangeMilestone( e.target.value, milestoneIndex, item.value ) }
                                >
                                  {
                                    props.disbursementList.length > 0 ? props.disbursementList.map(( option, index ) => (
                                      <MenuItem key={ index } value={ option.value } >{ option.label }</MenuItem>
                                    )) : (
                                      <MenuItem value={ '-' } >{ getTranslation( language, 'No Disbursement' ) }</MenuItem>
                                    )
                                  }
                                </Select>
                              )
                            }
                            {
                              item.type === 'date' && (
                                <DatePicker 
                                  className='form-date-picker'
                                  dateFormat="MMM yyyy"
                                  showMonthYearPicker
                                  selected={ typeof( milestone[ item.value ] ) === 'string'
                                    ? ( milestone[ item.value ]
                                      ? new Date( milestone[ item.value ] )
                                      : ''
                                    ) : milestone[ item.value ]
                                  }
                                  onChange={ val => onChangeMilestone( val, milestoneIndex, item.value ) }
                                />
                              )
                            }
                            {
                              ![ 'select', 'date' ].includes( item.type ) && (
                                <Form.Control
                                  type={ item.type }
                                  placeholder={ getTranslation( language, item.label ) }
                                  value={ milestone[ item.value ] }
                                  onChange={ e => onChangeMilestone( e.target.value, milestoneIndex, item.value ) }
                                />
                              )
                            }
                          </Form.Group>
                        ))
                      }
                      <Form.Group style={{ marginBottom: 10 }}>
                        <Button
                          variant='danger'
                          disabled={milestone.milestone_status !== 'Not Started'}
                          onClick={ () => removeMilestone( milestoneIndex )}>
                          X
                        </Button>
                      </Form.Group>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {
          props.disbursementList.length === 0 && (
            <p className='text-danger'>
              { getTranslation( language, `Please create disbursement before stage and milestone. You may also leave 'Utilization Fund' empty.` ) }
            </p>
          )
        }
        <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
          { getTranslation( language, 'Close' ) }
        </Button>
        <Button variant="primary" onClick={ handleSubmit }>
          { getTranslation( language, 'Update' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default StageMilestoneForm;