import _ from 'lodash'
import localStorage from 'localStorage'
import React, { useEffect, useState } from 'react'
import { Form } from "react-bootstrap"
import DatePicker  from 'react-datepicker'
import { FaFilePdf } from 'react-icons/fa'
import { useParams, useOutletContext } from 'react-router-dom';
import { Plagiarism, NavigateBefore, Clear } from '@mui/icons-material';

import LoadingModal from 'components/LoadingModal'
import PreviewModal from '../../components/preview'
import SubmitConfirmation from 'components/Card/Confirmation'

import OverallAssessmentHOC from './actions'
import {
  projectTeam,
  projectReporting,
  assessmentCretiria,
  projectAchievement,
  communityBeneficiary,
  collaborativePartners,
} from '../../ProjectAssessment/assets'
import { getTranslation } from 'utils/renderLanguage'
import { assessmentStatusId } from 'utils/stageStatus'
import { overallAssessmentField } from '../assets'
import '../index.scss'

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const language = localStorage.getItem( 'english' )

const fieldData = [
  { label: 'Nama Penilai', value: 'assessor_name' },
  { label: 'Tarikh', value: 'summission_date' },
  { label: 'Jumlah Markah', value: 'jumlah_markah' },
  { label: 'Pernilaian', value: 'pernilaian' },
  { 
    label: 'Based on your assessment, kindly provide your comment as follow:',
    children: [
      { label: 'Perkara/Pencapaian yang boleh dibanggakan. (Commendable Areas )', value: 'comment' },
      { label: 'Perkara yang diperbaiki. (Affirmative Areas )', value: 'affirmative' },
      { label: 'Other Remarks.', value: 'remark' },
      { label: 'Overall Grade', value: 'overall_grade' }
    ]
  },
]

const CreateOverallAssessment = props => {
  
  const outletContext = useOutletContext()
  const { project_id: selectedProjectId } = useParams()

  const [ numPages, setNumPages ] = useState( null )
  const [ pageNumber, setPageNumber ] = useState( 1 )

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  
  useEffect( () => {
    props.getProjectDetail( selectedProjectId )
    props.onChangeCreateOverallAssessment({
      name: outletContext.user.display_name,
      role: outletContext.user.display_name.role,
      created_by: outletContext.user.uid,
      progress: {},
      site_audit: {},
      recommendation: {}
    }, 'overallAssessment' )
    props.getProjectDetail( selectedProjectId )
  }, [])

  const onChangeField = ( value, content, section ) => {
    let temp = _.cloneDeep( props.overallAssessment )
    temp[ section ][ content ] = value
    props.onChangeCreateOverallAssessment( temp, 'overallAssessment' )
  }

  const renderFieldInput = ( item, section, index ) => {
    if( item.type === 'textarea' ) {
      return(
        <Form.Group key={ index } className='mt-3'>
          <Form.Label style={{ fontWeight: 500 }}>{ getTranslation( language, item.label ) }</Form.Label>
          <Form.Control
            as='textarea'
            type={ 'text' }
            style={{ minHeight: 100 }}
            placeholder={ getTranslation( language, item.label ) }
            value={ props.overallAssessment[ section ][ item.value ] }
            onChange={ e => onChangeField( e.target.value, item.value, section ) }
          />
        </Form.Group>
      )
    } else {
      return(
        <Form.Group key={ index } className='mt-3'>
          <Form.Label style={{ fontWeight: 500 }}>{ getTranslation( language, item.label ) }</Form.Label>
          <Form.Control
            type={ item.type }
            placeholder={ getTranslation( language, item.label ) }
            maxLength={ item.type === 'number' ? 3 : '' }
            disabled={ item.disabled }
            value={ props.overallAssessment[ section ][ item.value ] }
            onChange={ e => {
              let val = e.target.value
              if( item.type !== 'number' || ( item.type === 'number' && val <= 100 ) ){
                onChangeField( val, item.value, section ) 
              }
            }}
          />
        </Form.Group>
      )
    }
  }

  const renderAssessmentContent = ( criteria ) => {
    switch( criteria ){
      case 1:
        return projectReporting
      case 2:
        return projectAchievement
      case 3:
        return projectTeam
      case 4:
        return collaborativePartners
      case 5:
        return communityBeneficiary
    }
  }
  
  const renderCriteriaColumn = () => {

    return(
      <>
        {
          overallAssessmentField.map(( item, index ) => {
            return(
              <div key={ index } className='site-audit__section-cont'>
                <label className='title'>{ getTranslation( language, item.label ) }</label>
                {
                  item.children.map(( child , childIndex ) => (
                    renderFieldInput( child, item.value, childIndex )
                  ))
                }
              </div>
            )
          })
        }
        <button
          className='btn btn-success'
          style={{ marging: '10px 0px', width: 100 }}
          onClick={ () => props.onChangeCreateOverallAssessment( true, 'showSubmitConfirmation' ) } >
          Submit
        </button>
      </>
    )
  }
  
  return(
    <>
      <div className="d-flex align-items-center mb-5">
        <button className="back-button" onClick={ () => outletContext.onNavigate( -1 ) }>
          <NavigateBefore/>
        </button>
        <h4>
          { getTranslation( language, 'Overall Monitoring Assessment' ) }
          <span style={{ fontSize: 14, marginLeft: 5 }}>/ { selectedProjectId }</span>
        </h4>
      </div>
      <div className='d-flex justify-content-between mb-4'>
        <div className='year-selection'>
          <label>{ getTranslation( language, 'Year' ) }</label>
          <DatePicker
            className='form-date-picker'
            selected={ props.selectedYear }
            onChange={ date => props.onChangeCreateOverallAssessment( date, 'selectedYear' ) }
            dateFormat="QQQ, yyyy"
            showQuarterYearPicker
          />
        </div>
        <button
          className='btn btn-primary'
          style={{ marginTop: 'auto' }}
          onClick={ () => props.onChangeCreateOverallAssessment( true, 'showPreviewModal' ) }>
          <Plagiarism /> { getTranslation( language, 'Preview' ) }
        </button>
      </div>
      <div className='row mt-4 mx-1'>
        {
          props.selectedFile && (
            <div className='col-lg-8'>
              <div className='react-pdf-viewer__filename'>
                <FaFilePdf />{ props.selectedFilename || 'No file name' }
                <Clear style={{ cursor: 'pointer', marginLeft: 'auto', color: 'grey' }} onClick={ () => props.onChangeCreateOverallAssessment( '', 'selectedFile' ) } />
              </div>
              {
                props.selectedFilename === 'Project Assessment' && (
                  <div className='project-assessment-preview-cont'>
                    {
                      assessmentCretiria.map(( criteria, criteriaIndex ) => (
                        <div key={ criteriaIndex } className='project-assessment-criteria-cont'>
                          <label>{ criteria.label }</label>
                          {
                            criteria.id !== 6 && renderAssessmentContent( criteria.id ).map( item => {
                              let rating = assessmentStatusId( props.selectedFile[ criteria.id ][ item.id ] )
                              return(
                                <p>
                                  { item.label }
                                  <span
                                    className='badge__status'
                                    style={{ 
                                      width: 'fit-content',
                                      marginLeft: 10,
                                      fontSize: 10,
                                      background: rating.background,
                                      color: rating.color
                                    }}>{ rating.label }</span>
                                </p>
                              )
                            })
                          }
                          {
                            criteria.id === 6 && fieldData.map(( item, index ) => (
                              <>
                                <p style={{ fontWeight: 500, fontSize: 14, marginBottom: 5 }}>{ item.label }</p>
                                {
                                  item.children ? (
                                    <>
                                      {
                                        item.children.map( child => (
                                          <>
                                            <p style={{ fontWeight: 600, fontSize: 14, marginTop: 5 }}>{ child.label }</p>
                                            <p>{ props.selectedFile[ 6 ][ child.value ] }</p>
                                          </>
                                        ))
                                      }
                                    </>
                                  ) : (
                                    <p>{ props.selectedFile[ 6 ][ item.value ] }</p>
                                  )
                                }
                              </>
                            ))
                          }
                        </div>
                      ))
                    }
                  </div>
                )
              }
              {
                props.selectedFilename !== 'Project Assessment' && (
                  <>
                    <Document file={ props.selectedFile } onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={ pageNumber } />
                    </Document>
                    <div className='react-pdf-viewer__navigation'>
                      <button
                        style={{ width: 100 }}
                        className='btn btn-outline-secondary mr-2'
                        disabled={ pageNumber === 1 }
                        onClick={ () => setPageNumber( pageNumber - 1 ) }>
                        { getTranslation( language, 'Previous' ) }
                      </button>
                      <b className='m-2'>{ pageNumber }</b> / <b className='m-2'>{ numPages }</b>
                      <button
                        style={{ width: 100 }}
                        className='btn btn-outline-secondary ml-2'
                        disabled={ pageNumber === numPages }
                        onClick={ () => setPageNumber( pageNumber + 1 ) }>
                        { getTranslation( language, 'Next' ) }
                      </button>
                    </div>
                  </>
                )
              }
            </div>
          )
        }
        <div className={ `${ props.selectedFile ? 'col-lg-4' : 'col-lg-12' } site-audit__audit-cont` }>
          { renderCriteriaColumn() }
        </div>
      </div>
      {
        props.showPreviewModal && <PreviewModal
          kpi={ props.kpi }
          projectDetail={ props.projectDetail }
          activity_reports={ props.activity_reports }
          project_assessment={ props.project_assessment }
          summary_year_options={ props.summary_year_options }
          activity_reports_summary={ props.activity_reports_summary }
          onLoadProjectAssessment={ props.onLoadProjectAssessment }

          getKPIs={ props.getKPIs }
          getActivityReports={ props.getActivityReports }
          getProjectAssessment={ props.getProjectAssessment }
          onChangeHOC={ props.onChangeCreateOverallAssessment }
          setOpen={ val => props.onChangeCreateOverallAssessment( val, 'showPreviewModal' ) }  
        />
      }
      {
        props.showSubmitConfirmation && <SubmitConfirmation
          isOpen={ true }
          onClick={ () => props.submitOverallAssessment( outletContext.onNavigate ) }
          confirmationText={
            <>
              Are you sure to submit this Overall Monitoring Analysis to the project?
              <br/><br/>
              <b className='text-danger'>This action is non-reversible.</b>
            </>
          }
          setOpen={ val => props.onChangeCreateOverallAssessment( val, 'showSubmitConfirmation' ) }
        />
      }
      {
        props.onLoadOverallAssessment && <LoadingModal />
      }
    </>
  )
}

export default OverallAssessmentHOC( CreateOverallAssessment );