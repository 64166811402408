import React from 'react';
import { MdDashboard, MdPeople, MdOutlineScience } from 'react-icons/md'
import { BsCardChecklist } from "react-icons/bs"

import { getTranslation } from 'utils/renderLanguage'

export const SuperAdminItem = ( language ) => [
  {
    title: getTranslation( language, 'User Management' ),
    path:'/admin/user-management',
    icon: <MdPeople/>,
  },
  {
    title: getTranslation( language, 'Project Management' ),
    path: '/admin/project-management',
    icon: <MdOutlineScience/>,
  },
]

export const AdminItem = [
  {
    title: 'Home',
    path:'/home',
    icon: <MdDashboard/>,
  },
  {
    title: 'Project Management',
    path: '/admin/project-management',
    icon: <MdOutlineScience/>,
  },
]

export const LeaderItem = [
  {
    title: 'Home',
    path:'/home',
    icon: <MdDashboard/>,
  },
  {
    title: 'Project Mangement',
    path: '/project-management',
    icon: <MdOutlineScience/>,
  },
]

export const AssessorItem = [
  {
    title: 'Home',
    path:'/home',
    icon: <MdDashboard/>,
  },
  {
    title: 'Project Assessment',
    path: '/assessor/project-management',
    icon: <BsCardChecklist/>,
  },
]

export const SecretariatItem = [
  {
    title: 'Home',
    path:'/home',
    icon: <MdDashboard/>,
  },
  {
    title: 'Project Assessment',
    path: '/secretariat/project-management',
    icon: <BsCardChecklist/>,
  },
]