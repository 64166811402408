import React, { useState } from 'react'
import localStorage from 'localStorage'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { IconButton } from '@mui/material'
import { FaUserEdit } from 'react-icons/fa'
import { ChevronRight, Close } from '@mui/icons-material'
import { MdLogout, MdOutlineLockOpen } from 'react-icons/md'

import LoadingModal from "components/LoadingModal"
import LanguageToggle from 'components/Input/langguageToggle'
import UpdateProfile from './updateProfile'
import ChangeCredential from './changeCredential'
import UserInfoHOC from './actions'

import { getTranslation } from 'utils/renderLanguage'
import User from 'assets/user.png'
import Logo from 'assets/logo.png'
import './index.scss'


const UserInfo = props => {
  
  const user = JSON.parse( localStorage.getItem("userLogin"))
  const [ showChangeCredential, setShowChangeCredential ] = useState( false )
  const [ showUpdateProfile, setShowUpdateProfile ] = useState( false )

  const homeRedirect = () => {
    switch( user.role ){
      case 'Super Admin':
        return '/dashboard/admin/user-management'
      case 'Admin':
        return '/dashboard/admin/project-management'
      case 'Assessor':
        return '/dashboard/assessor/project-management'
      case 'Project Leader':
        return '/dashboard/project-management'
      case 'Secretariat':
        return '/dashboard/secretariat/project-management'
      default:
        return '/dashboard/home'
    }
  }

  return(
    <>
      <div className='topbar-container'>
        {
          user && (
            <>
              <div className='d-flex align-items-center'>
                {
                  user.role === 'Super Admin' && (
                    <IconButton 
                      classes={{ root: props.sidebar ? 'toggle-menu' : 'toggle-menu-expand' }}
                      onClick={ () => props.setSidebar( !props.sidebar ) }>
                      {
                        props.sidebar ? <ChevronRight style={{ color: 'white' }}/> : <Close style={{ color: 'white' }}/>
                      }
                    </IconButton>
                  )
                }
                <Link to={ homeRedirect() } className='logo-bars' >
                  <img style={{ width: '100%', height: 60, paddingBottom: 10, paddingLeft: 10 }} src={ Logo } alt={ 'logo' } />
                </Link>
              </div>
              <Dropdown className='d-flex align-items-center'>
                <LanguageToggle />
                <Dropdown.Toggle className='user-button-container' variant="primary" id="dropdown-basic">
                  <img src={ User } alt={ 'user' } />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className={ 'top-bar__user-info' }>
                    <p id={ 'display-name' }>{ user.display_name }</p>
                    <p id={ 'role' }>{ user.role }</p>
                  </div>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={ () => setShowUpdateProfile( true ) }>
                    <FaUserEdit style={{ marginRight: 10, width: 14 }} />
                    { getTranslation( props.language, 'Update Profile' ) }
                  </Dropdown.Item>
                  <Dropdown.Item onClick={ () => setShowChangeCredential( true ) }>
                    <MdOutlineLockOpen style={{ marginRight: 10, width: 14 }} />
                    { getTranslation( props.language, 'Change Email/Password' ) }
                  </Dropdown.Item>
                  <Dropdown.Item 
                    onClick={ () => Promise.all([
                      props.signOutUser() 
                    ]).then( () => {
                      props.onNavigate( '/login' )
                    })}>
                    <MdLogout style={{ marginRight: 10, width: 14 }}/>
                    { getTranslation( props.language, 'Sign Out' ) }
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )
        }
      </div>
      {
        showChangeCredential && <ChangeCredential 
          setOpen={ setShowChangeCredential }
          changeUserCredential={ props.changeUserCredential }
          user={ user }
        />
      }
      {
        showUpdateProfile && <UpdateProfile
          setOpen={ setShowUpdateProfile }
          updateProfile={ props.updateProfile }
          user={ user }
        />
      }
      { props.onLoading && <LoadingModal />}
    </>
  )
}

export default UserInfoHOC( UserInfo );