import React, { Component } from 'react'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      showEditDisbursementModal: false,
      showCreateDisbursementModal: false,
      showDeleteDisbursementModal: false,

      disbursement: [],
      selectedDisbursement: {},
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeFinancialHOC = ( val, context ) => this.setState({ [ context ]: val })

    getDisbursements = disbursements => Get(
      `/financial-getDisbursements?disbursements=${ JSON.stringify( disbursements ) }`,
      this.getDisbursementsSuccess,
      this.getDisbursementsError,
      this.load
    )
    getDisbursementsSuccess = payload => this.setState({ disbursement: payload.data.disbursement })
    getDisbursementsError = error => this.requestError( error )

    createDisbursement = dataToSubmit => Post(
      `/financial-newDisbursement`,
      dataToSubmit,
      this.createDisbursementSuccess,
      this.createDisbursementError,
      this.load
    )
    createDisbursementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showCreateDisbursementModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    createDisbursementError = error => this.requestError( error )

    updateDisbursement = dataToSubmit => Post(
      `/financial-adminUpdateDisbursement`,
      dataToSubmit,
      this.updateDisbursementSuccess,
      this.updateDisbursementError,
      this.load
    )
    updateDisbursementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showEditDisbursementModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    updateDisbursementError = error => this.requestError( error )

    deleteDisbursement = id => Delete (
      `/financial-deleteDisbursement?id=${ id }`,
      this.deleteDisbursementSuccess,
      this.deleteDisbursementError,
      this.load
    )
    deleteDisbursementSuccess = payload => {
      this.requestSuccess( payload.data.message )
      this.setState({ showDeleteDisbursementModal: false })
      this.props.getProjectDetail( this.props.projectDetail.project_id )
    }
    deleteDisbursementError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadFinancial={ this.state.loading }

            getDisbursements={ this.getDisbursements }
            createDisbursement={ this.createDisbursement }
            updateDisbursement={ this.updateDisbursement }
            deleteDisbursement={ this.deleteDisbursement }
            onChangeFinancialHOC={ this.onChangeFinancialHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC