import React, { useEffect } from 'react'
import { useOutletContext } from "react-router-dom";
import { ScienceOutlined, EditOutlined, Info, DeleteForeverRounded } from '@mui/icons-material'

import InfoCard from 'components/Card/InfoCard'
import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'
import ModuleHeader from 'components/Card/ModuleHeader'
import DeleteConfirmation from 'components/Card/Confirmation'

import CreateProjectForm from './form'
import UpdateProjectForm from './form'

import { getTranslation } from 'utils/renderLanguage'
import ProjectManagementHOC from './actions'
import './index.scss'

const ProjectManagement = props => {
  
  const outletContext = useOutletContext()
  const language = outletContext.language
  const cardList = [
    { label: getTranslation( language, 'Total Project' ), value: 'total_project' },
    { label: getTranslation( language, 'Active Project' ), value: 'active_project' },
    { label: getTranslation( language, 'Pending Project' ), value: 'pending_project' },
    { label: getTranslation( language, 'Rejected Project' ), value: 'rejected_project' },
  ]
  
  const columnData = [
    { header: getTranslation( language, 'Project Name' ), accessor: 'project_name' },
    { header: getTranslation( language, 'Description' ), accessor: 'project_description', containerStyle: { width: '200%' } },
    { header: getTranslation( language, 'Implementing Agency' ), accessor: 'implementing_agency' },
    { header: getTranslation( language, 'State' ), accessor: 'state', containerStyle: { width: '50%' } },
    { header: getTranslation( language, 'Status' ), accessor: 'status', containerStyle: { width: '50%' } },
  ]

  useEffect( () => {
    props.getAllProject()
    outletContext.getUserDictionary()
    outletContext.getProjectDictionary()
  }, [])

  return(
    <>
      <ModuleHeader 
        title={ getTranslation( language, 'Project Management' ) }
        description={ getTranslation( language, 'This module use to manage all the project in the system.' ) }
        actionButton={[{
          label: getTranslation( language, 'Create Project' ),
          className: 'btn btn-primary',
          onClick: () => props.onChangeProjectManagementHOC( true, 'showCreateModal' )
        }, {
          label: getTranslation( language, 'Export Excel' ),
          className: 'btn btn-primary',
          onClick: () => props.onChangeProjectManagementHOC( true, 'showExportConfirmation' )
        }]}
        renderIcon={ () => <ScienceOutlined /> }
      />
      <InfoCard
        cardList={ cardList }
        dataValue={ props.dataValue }
      />
      <PaginatedTable
        rowData={ props.projectList || [] }
        columnData={ columnData }
        actionsColumn={[{
          name: 'View',
          renderActionIcon: <Info />,
          onClick: rowData => outletContext.onNavigate( `/dashboard/admin/project-management/${ rowData.project_id }` )
        },{
          name: 'Edit',
          renderActionIcon: <EditOutlined />,
          onClick: rowData => Promise.all([
            props.onChangeProjectManagementHOC( rowData, 'selectedProject' )
          ]).then( () => {
            props.onChangeProjectManagementHOC( true, 'showEditModal' )
          })
        }, {
          name: 'Delete',
          style: { background: '#cb1515' },
          renderActionIcon: <DeleteForeverRounded />,
          onClick: rowData => Promise.all([
            props.onChangeProjectManagementHOC( rowData, 'selectedProject' )
          ]).then( () => {
            props.onChangeProjectManagementHOC( true, 'showDeleteModal' )
          })
        }]}
      />
      {
        props.showCreateModal && <CreateProjectForm
          mode={ 'create' }
          title={ getTranslation( language, 'Create New Project' ) }
          currentUser={ outletContext.user }
          language={ outletContext.language }
          userDictionary={ outletContext.userDictionary }
          createNewProject={ props.createNewProject }
          setOpen={ val => props.onChangeProjectManagementHOC( val, 'showCreateModal' ) }
        />
      }
      {
        props.showEditModal && <UpdateProjectForm
          mode={ 'update' }
          title={ getTranslation( language, 'Update Project' ) }
          currentUser={ outletContext.user }
          language={ outletContext.language }
          selectedProject={ props.selectedProject }
          userDictionary={ outletContext.userDictionary }
          updateProject={ props.updateProject }
          setOpen={ val => props.onChangeProjectManagementHOC( val, 'showEditModal' ) }
        />
      }
      {
        props.showExportConfirmation && <DeleteConfirmation
          isOpen={ true }
          onClick={ () => props.exportCSV() }
          confirmationText={
            <>
              { getTranslation( language, 'You are going to export all project.' ) }
              <br/><br/>
              <b className='text-danger'>{ getTranslation( language, 'This action is non-reversible.' ) }</b>
            </>
          }
          setOpen={ val => props.onChangeProjectManagementHOC( val, 'showExportConfirmation' ) }
        />
      }
      {
        props.showDeleteModal && <DeleteConfirmation
          isOpen={ true }
          onClick={ () => props.deleteProject( props.selectedProject.project_id ) }
          confirmationText={
            <>
              { getTranslation( language, 'You are going to remove the selected project ' ) }<b>({ props.selectedProject.project_name })</b>{ getTranslation( language, ' from the system and this will also remove the project from Assessor and Project Leader.' ) }
              <br/><br/>
              <b className='text-danger'>{ getTranslation( language, 'This action is non-reversible.' ) }</b>
            </>
          }
          setOpen={ val => props.onChangeProjectManagementHOC( val, 'showDeleteModal' ) }
        />
      }
      {
        props.onLoadProjectManagement && <LoadingModal />
      }
    </>
  )
}

export default ProjectManagementHOC( ProjectManagement );