import React from 'react'
import { Tooltip } from '@mui/material';

const CustomButton = ({
  className,
  style,
  containerClass,
  conatinerStyle,
  tooltipContent,
  onClick,
  disabled,
  content
}) => {

  return (
    <div className={ `${ containerClass || '' }` } style={{ width: '100%', ...conatinerStyle }}>
      {
        ( !disabled && tooltipContent ) ? (
          <Tooltip placement='top' title={ tooltipContent }>
            <button
              disabled={ disabled }
              className={ `${ className }` }
              style={{ ...style }}
              onClick={ () => onClick()}>
              { content }
            </button>
          </Tooltip>
        ) : (
          <button
            disabled={ disabled }
            className={ `${ className }` }
            style={{ ...style }}
            onClick={ () => onClick()}>
            { content }
          </button>
        )
      } 
    </div>
  )
}

export default CustomButton;