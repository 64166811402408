import React, { useState, useEffect } from 'react'
import localStorage from 'localStorage'

import './langguageToggle.scss'

const LanguageToggle = ({
  style,
  className
}) => {

  const [ isEnglish, setEnglish ] = useState( true )

  useEffect( () => {
    const english = localStorage.getItem( 'english' )
    if( english !== null ){
      setEnglish( english === 'EN' )
    } else {
      setEnglish( true )
    }
  }, [])

  const onToggle = ( english ) => {
    setEnglish( english )
    localStorage.setItem( 'english', english ? 'EN' : 'BM' )
    window.location.reload()
  }

  return (
    <div className={ className } style={{ ...style }}>
      <div class="switch">
        <input
          type="checkbox"
          id="language-toggle"
          checked={ isEnglish }
          class="check-toggle check-toggle-round-flat"
          onChange={ e => onToggle( e.target.checked ) } />
        <label for="language-toggle"></label>
        <span class="on">BM</span>
        <span class="off">EN</span>
      </div>
    </div>
  )
}

export default LanguageToggle
