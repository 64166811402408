import { Modal, Button } from "react-bootstrap"
import localStorage from 'localStorage'
import { getTranslation } from 'utils/renderLanguage'

const language = localStorage.getItem( 'english' )

const Confirmation = (props) => {

  return (
    <Modal show={props.isOpen} onHide={() => props.setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{ getTranslation( language, 'Are you sure?' ) }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.confirmationText}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen(false)}>
          { getTranslation( language, 'Cancel' ) }
        </Button>
        <Button
          variant="outline-primary"
          onClick={ props.onClick }>
          { getTranslation( language, 'Confirm' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Confirmation
