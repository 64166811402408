import React from 'react'

import './index.scss'

const ModuleHeader = ({
  title,
  renderIcon,
  description,
  actionButton,
}) => {

  return (
    <div className='module-header__card'>
      {
        renderIcon && renderIcon()
      }
      <div className='row' style={{ width: '93%' }}>
        <div className='col-lg-8'>
          <p className='module-header__card-title'>{ title }</p>
          <p className='module-header__card-subtitle'>{ description }</p>
        </div>
        <div className='module-header__card-action-cont col-lg-4'>
          {
            ( actionButton && actionButton.length > 0 ) && actionButton.map(( item, index ) => {
              return(
                <button 
                  key={ index }
                  className={ item.className }
                  onClick={ () => item.onClick() }
                >
                  { item.label }
                </button>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default ModuleHeader;