import React, { useEffect, useState } from "react";
import { NavigateBefore } from "@mui/icons-material";
import { useParams, useOutletContext } from 'react-router-dom';

import Tab from 'components/Tab'
import LoadingModal from "components/LoadingModal";
import ProjectAssessment from "../../ProjectAssessment";
import OverallAssessment from "../../OverallAnalysis";
import SiteAudit from "../../SiteAudit";
import Overview from "./overview";

import { getTranslation } from 'utils/renderLanguage'
import ProjectDetailsHOC from './actions'
import './index.scss'

const ProjectViewForm = props => {

  const { language, onNavigate } = useOutletContext()
  const { project_id: selectedProjectId } = useParams()
  const [ selectedTab, setSelectedTab ] = useState( 'Overview' )

  useEffect( () => {
    props.getProjectDetail( selectedProjectId )
  }, [ selectedProjectId ])
  
  return(
    <>
      <div className="d-flex align-items-center">
        <button className="back-button" onClick={ () => onNavigate( -1 ) }>
          <NavigateBefore/>
        </button>
        <h4>
          { getTranslation( language, 'Project Details' ) }
          <span style={{ fontSize: 12, marginLeft: 5 }}>({ selectedProjectId })</span>
        </h4>
      </div>
      <Tab
        selections={ [ 'Overview', 'Project Progress Assessment', 'Site Audit Assessment', 'Overall Monitoring Analysis' ] }
        selectedItem={ selectedTab }
        onSelect={ setSelectedTab }
      />
      <hr/>
      { selectedTab === 'Overview' && <Overview { ...props } /> }
      { selectedTab === 'Project Progress Assessment' && <ProjectAssessment { ...props } /> }
      { selectedTab === 'Site Audit Assessment' && <SiteAudit { ...props } /> }
      { selectedTab === 'Overall Monitoring Analysis' && <OverallAssessment { ...props } /> }
      { props.onLoadProjectDetails && <LoadingModal /> }
    </>
  )
}

export default ProjectDetailsHOC( ProjectViewForm );