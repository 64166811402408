import _ from 'lodash';
import React, { useEffect } from 'react'
import { Doughnut, Bar } from 'react-chartjs-2'
import { useOutletContext } from "react-router-dom";
import { ArrowForward  } from '@mui/icons-material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';

import SliderCard from 'components/Card/SliderCard'

import HomePageHOC from './actions'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './index.scss'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const HomePage = props => {

  const outletContext = useOutletContext()
  const { uid, role } = outletContext.user
  
  useEffect( () => {
    if( role && role.includes( 'Admin' ) ){
      props.getAllProjects()
      props.getUserStatistic()
    } else {
      props.getProjects( uid )
    }
  }, [] )

  const renderCardContent = element => {

    const percentage = Math.ceil( element.progress_percentage )

    return(
      <>
        { element.project_name }
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <div style={{ width: 80, height: 80 }}>
            <CircularProgressbar
              value={ percentage }
              text={ `${ percentage }%` }
              strokeWidth={ 10 }
              styles={{
                path: {
                  stroke: '#00c19a',
                },
                trail: {
                  stroke: '#D1D5DB',
                },
                text: {
                  fill: '#00c19a'
                }
              }}
            />
          </div>
          <button onClick={ () => outletContext.onNavigate( `/dashboard/project-management/${ element.project_id }` ) } >
            <ArrowForward />
          </button>
        </div>
      </>
    )
  }

  return(
    <>
      {
        ( props.projectList && props.projectList.length > 0 ) && (
          <SliderCard
            dataList={ props.projectList }
            renderCardContent={ renderCardContent }
          />
        )
      }
      {
        !( props.projectList && props.projectList.length > 0 ) && (
          <div>No Result</div>
        )
      }
      <div className='row mx-4'>
        {
          role.includes( 'Admin' ) && (
            <div className='col-lg-8'>
              <div className='at-home__card'>
                {
                  !_.isEmpty( props.userRoleGraph ) && (
                    <>
                      <div style={{ float: 'right' }}>
                        <label>Total User</label>
                        <p>{ props.userRole.total_user }</p>
                      </div>
                      <Bar 
                        data={ props.userRoleGraph }
                        options={{
                          plugins: {
                            legend: {
                              position: 'bottom',
                            }
                          }
                        }}
                      />
                    </>
                  )
                }
                {
                  _.isEmpty( props.userRoleGraph ) && <div>No Result</div>
                }
              </div>
              <div className='at-home__card'>
                {
                  !_.isEmpty( props.userStatusGraph ) && (
                    <>
                      <div style={{ float: 'right' }}>
                        <label>Total User</label>
                        <p>{ props.userStatus.total_user }</p>
                      </div>
                      <Bar 
                        data={ props.userStatusGraph }
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: 'bottom',
                            }
                          }
                        }}
                      />
                    </>
                  )
                }
                {
                  _.isEmpty( props.userStatusGraph ) && <div>No Result</div>
                }
              </div>
            </div>
          )
        }
        <div className='col-lg-4'>
          <div className='at-home__card'>
            {
              !_.isEmpty( props.projectStatusGraph ) && (
                <>
                  <div style={{ float: 'right' }}>
                    <label>Total Project</label>
                    <p>{ props.projectStatus.total_project }</p>
                  </div>
                  <Doughnut 
                    data={ props.projectStatusGraph }
                    options={{
                      plugins: {
                        legend: {
                          position: 'bottom'
                        }
                      }
                    }}
                  />
                </>
              )
            }
            {
              _.isEmpty( props.projectStatusGraph ) && <div>No Result</div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePageHOC( HomePage );