import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import localStorage from 'localStorage'
import { Modal } from "react-bootstrap"
import { Select, MenuItem, Tooltip } from '@mui/material'
import { Description, OpenInNew, KeyboardArrowRight } from '@mui/icons-material'

import Tab from 'components/Tab'
import CardList from 'components/Card/CardList'

import { monthList } from 'utils/dictionary'
import { assessmentStatus } from 'utils/stageStatus'
import { getTranslation } from 'utils/renderLanguage'


const language = localStorage.getItem( 'english' )

const activity_report_link = [
  { label: getTranslation( language, 'Image Folder Link' ), value: 'image_folder_link' },
  { label: getTranslation( language, 'Video Folder Link' ), value: 'video_folder_link' },
  { label: getTranslation( language, 'Publication Folder Link' ), value: 'publication_folder_link' },
  { label: getTranslation( language, 'Other Folder Link' ), value: 'others_folder_link' },
]

const assessmentColumn = ( onChangeHOC, setOpen ) => [
  { header: getTranslation( language, 'Assessment By' ), accessor: 'assessor_name' },
  { header: getTranslation( language, 'Total Score' ), accessor: 'jumlah_markah' },
  { header: getTranslation( language, 'Rate at' ), accessor: 'updated_at' },
  { header: '', 
    className: 'd-flex',
    renderColumn: rowData => (
    <button
      className='btn'
      style={{ color: '#007eff', fontWeight: 500, fontSize: 14, marginLeft: 'auto' }}
      onClick={ () => {
        onChangeHOC( `Project Assessment`, 'selectedFilename' )
        onChangeHOC( rowData, 'selectedFile' )
        setOpen(false)
      }}>
      { getTranslation( language, 'View' ) }
      <KeyboardArrowRight style={{ width: 16, marginRight: 5 }}/>
    </button>
  )}
]

const DocumentPreview = props => {

  const [ tabSelection, setTabSelection ] = useState( [ 'Activity Report', 'KPI' ] )
  const [ selectedTab, setSelectedTab ] = useState( 'Activity Report' )
  const [ selectedYear, setSelectedYear ] = useState( new Date().getFullYear() )

  useEffect( () => {
    props.getActivityReports( props.projectDetail.activity_report )
    if( props.getProjectAssessment ){
      props.getProjectAssessment( props.projectDetail.project_id )
      setTabSelection( [ 'Activity Report', 'KPI', 'Project Assessment' ] )
    }
  }, [] )

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ getTranslation( language, 'Preview Document' ) }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab
          selections={ tabSelection }
          selectedItem={ selectedTab }
          onSelect={ val => {
            setSelectedTab( val ) 
            if( val === 'Activity Report' )
              props.getActivityReports( props.projectDetail.activity_report )
            else
              props.getKPIs( props.projectDetail.kpi_indication )
          }}
        />
        <hr/>
        <label style={{ fontWeight: 600, fontSize: 20, paddingLeft: 15 }}>{ getTranslation( language, selectedTab ) }</label>
        {
          selectedTab === 'Activity Report' && (
            <div className='year-selection'>
              <label>{ getTranslation( language, 'Year' ) }</label>
              <Select
                fullWidth
                value={ selectedYear || [] }
                style={{ borderRadius: `0.375rem`, borderColor: '#ced4da', height: 50, width: 190 }}
                onChange={ e => setSelectedYear( e.target.value ) } >
                {
                  props.summary_year_options.map(( option, index ) => (
                    <MenuItem key={ index } value={ option } >{ option }</MenuItem>
                  ))
                }
              </Select>
            </div>
          )
        }
        <div className={ 'row m-1' }>
          {
            selectedTab === 'KPI' && props.kpi.map(( kpi, kpiIndex ) => {
              return(
                <div key={ kpiIndex } className={ 'col-lg-3 col-md-4 mt-3' }>
                  <div className='project-assessment__preview-card' style={{ borderColor: kpi.kpi_document ? 'green' : 'red' }}>
                    <label className='text-start'>{ kpi.kpi_title }</label>
                    <p>{ kpi.kpi_description }</p>
                    <p>{ kpi.kpi_submission_date || 'No Submission Date' }</p>
                    <p>{ kpi.kpi_submission_note || 'No Submission Note' }</p>
                    <div className='w-100 d-flex justify-content-end'>
                      <Tooltip title={ getTranslation( language, 'View in PDF Viewer' ) } placement='top'>
                        <Description
                          style={ kpi.kpi_document_url ? { color: '#00C2FF', cursor: 'pointer' } : { color: '#B8B8B8', cursor: 'not-allowed' }}
                          onClick={ () => {
                            if( kpi.kpi_document_url ){
                                props.onChangeHOC( `Document for ${ kpi.kpi_title }`, 'selectedFilename' )
                                props.onChangeHOC( kpi.kpi_document_url, 'selectedFile' )
                                props.setOpen(false)
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )
            })
          }
          {
            selectedTab === 'Activity Report' && (
              <>
                {
                  monthList.map(( month, monthIndex ) => {
                    let activity = props.activity_reports_summary[ selectedYear ]
                      ? props.activity_reports_summary[ selectedYear ][ month ] || {}
                      : {}

                    return(
                      <div key={ monthIndex } className={ 'col-xl-3 col-lg-4 col-sm-6 mt-3' }>
                        <div className='project-assessment__preview-card' style={{ borderColor: _.isEmpty( activity ) ? 'red' : 'green' }}>
                          <label>{ month }</label>
                          {
                            activity_report_link.map( link => (
                              <button 
                                disabled={ !activity[ link.value ] }
                                onClick={ () => window.open( activity[ link.value ], '_blank' )}>
                                <OpenInNew /> { link.label }
                              </button>
                            ))
                          }
                          <div className='w-100 d-flex justify-content-end'>
                            <Tooltip title="View in PDF Viewer" placement='top'>
                              <Description
                                style={ activity.activity_report_file_url ? { color: '#00C2FF', cursor: 'pointer' } : { color: '#B8B8B8', cursor: 'not-allowed' }}
                                onClick={ () => {
                                  if( activity.activity_report_file_url ){
                                      props.onChangeHOC( activity.activity_report_file_name, 'selectedFilename' ) 
                                      props.onChangeHOC( activity.activity_report_file_url, 'selectedFile' ) 
                                      props.setOpen(false)
                                  }
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }    
              </>
            )
          }
        </div>
        {
          selectedTab === 'Project Assessment' && (
            <CardList
              columns={ assessmentColumn( props.onChangeHOC, props.setOpen ) }
              data={ props.project_assessment || [] }
              renderStatus={ status => (
                <div className='at-card-list__status' style={ assessmentStatus( status) }>
                  { status }
                </div>  
              )} />
          )
        }
      </Modal.Body>
  </Modal>
  )
}

export default DocumentPreview;