import React, { useEffect } from 'react'
import _ from 'lodash'
import { useOutletContext } from "react-router-dom";
import { ScienceOutlined, InfoTwoTone, EditTwoTone } from '@mui/icons-material'

import SearchForm from 'components/Search';
import CardList from 'components/Card/CardList'
import LoadingModal from 'components/LoadingModal'
import ModuleHeader from 'components/Card/ModuleHeader'
import CreateProjectForm from 'container/Dashboard/Admin/ProjectManagement/form'
import UpdateProjectForm from 'container/Dashboard/Admin/ProjectManagement/form'

import { columns } from './assets';
import ProjectManagementHOC from './actions'
import { getTranslation } from 'utils/renderLanguage'

import './index.scss'

const ProjectManagement = props => {
  
  const outletContext = useOutletContext()
  const language = outletContext.language
  const { uid, role, pending_task } = outletContext.user

  useEffect( () => {
    props.getProjects( uid )
  }, [])

  const onRefreshUser = () => Promise.all([
    outletContext.getUser( uid )
  ]).then( () => {
    props.getProjects( uid )
  })

  const renderSliderCard = item => (
    <div className='at-card-list__card-cont my-2'>
      {
        _.find( props.projectList, { project_id : item.project_id }) ? (
          <p>{ getTranslation( language, 'There is a pending assessment for' ) } { _.find( props.projectList, { project_id : item.project_id }).project_name }</p>
        ) : (
          <p>{ getTranslation( language, 'There is a pending assessment' ) }</p>
        )
      }
      <span className='at-project-mngmt__pending-task_timestamp'>{ item.created_at }</span>
    </div>
  )

  const renderProjectTable = () => (
    <>
      <SearchForm { ...props } />
      <CardList
        columns={ columns({
          selected_year: props.selected_year
        }) }
        data={ props.searchProjectList || [] }
        actions={[{
          label: (
            <>
              <InfoTwoTone style={{ marginRight: 5 }}/>
              { getTranslation( language, 'View' ) }
            </>
          ),
          onClick: rowData => outletContext.onNavigate( `/dashboard/${ role.toLowerCase() }/project-management/${ rowData.project_id }` )
        }, ...( role === 'Secretariat' ? [{
          label: (
            <>
              <EditTwoTone style={{ marginRight: 5 }}/>
              { getTranslation( language, 'Edit' ) }
            </>
          ),
          onClick: rowData => Promise.all([
            props.onChangeProjectManagementHOC( rowData, 'selectedProject' )
          ]).then( () => {
            props.onChangeProjectManagementHOC( true, 'showEditModal' )
          })
        }] : [] )
        ]}
      />
    </>
  )

  return(
    <>
      <ModuleHeader 
        title={ getTranslation( language, 'Project Management' ) }
        description={ getTranslation( language, 'This module use to manage all the project in the system.' ) }
        actionButton={ role === 'Secretariat' && [{
          label: getTranslation( language, 'Create Project' ),
          className: 'btn btn-primary',
          onClick: () => props.onChangeProjectManagementHOC( true, 'showCreateModal' )
        }]}
        renderIcon={ () => <ScienceOutlined /> }
      />
      {
        role === 'Assessor' && (
          <div className='row w-100'>
            <div className='col-xl-9 mb-2'>
              <div className='at-project-mngmt__white-card'>
                { renderProjectTable() }
              </div>
            </div>
            <div className='col-xl-3'>
              <div className='at-project-mngmt__white-card'>
                <p className='title'>{ getTranslation( language, 'Pending Task' ) }</p>
                {
                  ( pending_task && pending_task.length > 0 ) && pending_task.map( task => renderSliderCard( task ) )
                }
              </div>
            </div>
          </div>
        )
      }
      {
        role !== 'Assessor' && renderProjectTable()
      }
      {
        props.showCreateModal && <CreateProjectForm
          mode={ 'create' }
          title={ getTranslation( language, 'Create New Project' ) }
          currentUser={ outletContext.user }
          userDictionary={ outletContext.userDictionary }
          createNewProject={ dataToSubmit => props.createNewProject( dataToSubmit, onRefreshUser ) }
          setOpen={ val => props.onChangeProjectManagementHOC( val, 'showCreateModal' ) }
        />
      }
      {
        props.showEditModal && <UpdateProjectForm
          mode={ 'update' }
          title={ getTranslation( language, 'Update Project' ) }
          currentUser={ outletContext.user }
          selectedProject={ props.selectedProject }
          userDictionary={ outletContext.userDictionary }
          updateProject={ dataToSubmit => props.updateProject( dataToSubmit, onRefreshUser ) }
          setOpen={ val => props.onChangeProjectManagementHOC( val, 'showEditModal' ) }
        />
      }
      {
        props.onLoadProjectManagement && <LoadingModal />
      }
    </>
  )
}

export default ProjectManagementHOC( ProjectManagement );