import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Form, Modal, Button } from "react-bootstrap"
import { getTranslation } from 'utils/renderLanguage'

const fieldData = [
  { label: 'Disbursement', value: 'disbursement_title', type: 'text' },
  { label: 'Amount(RM)', value: 'amount', type: 'number' },
]

const DisbursementForm = props => {

  const { language } = props
  const [ disbursement, setDisbursement ] = useState({
    disbursement_title: '',
    funding: '',
    amount: '',
    project_completion: '',
    disbursement_date: '',
    disbursement_file: '',
    project_id: props.projectDetail.project_id,
  })

  useEffect( () => {
    if( props.selectedDisbursement && props.mode === 'update' ){
      setDisbursement( props.selectedDisbursement )
    }
  }, [] )

  const handleSubmit = () => {
    if( props.mode === 'create' )
      props.createDisbursement( disbursement )
    else 
      props.updateDisbursement( disbursement )
  }

  const onChangeField = ( val, context ) => {
    let temp = _.cloneDeep( disbursement )
    temp[ context ] = val
    if (context === 'amount') {
      temp.funding = (val / props.projectDetail.total_grant) * 100
    }
    setDisbursement( temp )
  }

  return(
    <Modal show={ true } onHide={() => props.setOpen(false)} size="xl" >
      <Modal.Header closeButton>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body className='row'>
        {
          fieldData.map(( item, index ) => {
            return(
              <Form.Group key={ index } className='mt-3 col-lg-6'>
                <Form.Label>{ getTranslation( language, item.label ) }</Form.Label>
                <Form.Control
                  type={ item.type }
                  placeholder={ getTranslation( language, item.label ) }
                  disabled={ item.disabled }
                  value={ disbursement[ item.value ] }
                  onChange={ e => onChangeField( e.target.value, item.value ) }
                />
              </Form.Group>
            )
          })
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => props.setOpen( false ) } >
          { getTranslation( props.language, 'Close' ) }
        </Button>
        <Button
          variant="primary"
          onClick={ handleSubmit } >
          { getTranslation( props.language, 'Submit' ) }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DisbursementForm;