export const thousandSeperatorFormatter = (val) => {

  if ( !val ) {
    return 0.00
  }

  let temp = val.replace(/[^0-9.]/g, '');
  temp = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(temp);

  return temp
}
export const numericFormatter = val => {
  let numericValue = val.replace(/,/g, '');
  numericValue = parseFloat(numericValue).toFixed(2);

  return parseFloat(numericValue)
}