import React, { Component } from 'react'
import Moment from 'moment'
import _ from 'lodash'
import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      loading: false,
      loadingCount: 0,
      showEditModal: false,
      showCreateModal: false,
      showSubmissionModal: false,
      showDeleteConfirmation: false,

      siteAssessmentList: [],
      this_month_submitted: false,
      selectedSiteAssessemt: {},
      showViewModal: false,
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeSiteAssessmentHOC = ( val, context ) => this.setState({ [ context ]: val })

    getSiteAssessment = ( id, project_id ) => Get(
      `/assessment-getSiteAudits?id=${ id }&project_id=${ project_id }`,
      this.getSiteAssessmentSuccess,
      this.getSiteAssessmentError,
      this.load
    )
    getSiteAssessmentSuccess = payload => {
      let this_month_submitted = false
      let temp = _.cloneDeep( payload.data.site_audits )
      temp.map( item => {
        if( item.date === `Q${ Moment().format( 'Q, yyyy' ) }` ){
          this_month_submitted = true
        }
      })
      this.setState({ 
        siteAssessmentList: payload.data.site_audits,
        this_month_submitted: this_month_submitted,
      })
    }
    getSiteAssessmentError = error => this.requestError( error )

    getAllSiteAssessment = id => Get(
      `/assessment-getSiteAudits?project_id=${ id }`,
      this.getSiteAssessmentSuccess,
      this.getSiteAssessmentError,
      this.load
    )
    
    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadSiteAssessment={ this.state.loading }

            getSiteAssessment={ this.getSiteAssessment }
            getAllSiteAssessment={ this.getAllSiteAssessment }
            onChangeSiteAssessmentHOC={ this.onChangeSiteAssessmentHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC