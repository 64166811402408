import React, { Component } from 'react'
import _ from 'lodash'
import Moment from 'moment'

import CustomSnackbar from 'components/Snackbar';

import { Get, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {

    state = {
      showSnackbar:false,
      snackbarMessage: '',
      snackbarType: '',
      onCloseSnackbar: () => {},

      kpi: [],
      activity_reports: [],
      summary_year_options: [],
      activity_reports_summary: {},

      loading: false,
      loadingCount: 0,
      projectDetail: {
        project_name: '',
        project_description: '',
        implementing_agency: '',
        assessor: '',
        project_leader: '',
        current_stage: '',
        start_year: '',
        funder: '',
        state: '',
        status: '',
        activity_report: [],
        kpi_indication: [],
        achievement_indication: [],
        disbursement_progress: [],
        utilization_progress: [],
        stage_and_milestone: [],
        assessment: [],
        site_audit: []
      },
      siteAudit: {
        perkara_1:'',
        perkara_2:'',
        perkara_3:'',
        perkara_4:'',
        perkara_5:'',
      },
      selectedYear: new Date(),
      selectedFile: '',
      selectedFilename: '',
      showPreviewModal: false,
    }

    load = param => {
      this.setState((prevState) => {
        const newCount = param ? prevState.loadingCount + 1 : prevState.loadingCount - 1
        return {
          loading: newCount > 0,
          loadingCount: newCount
        }
      })
    }
    requestError = error => this.setState({
      showSnackbar: true,
      snackbarType: 'error',
      onCloseSnackbar: () => this.setState({ showSnackbar: false }),
      snackbarMessage: error
    })
    requestSuccess = ( success, callback ) => this.setState({
      showSnackbar: true,
      snackbarType: 'success',
      onCloseSnackbar: () => {
        this.setState({ showSnackbar: false })
        callback && callback()
      },
      snackbarMessage: success
    })
    onChangeSiteAuditHOC = ( val, context ) => this.setState({ [ context ]: val })

    getProjectDetail = id => Get(
      `/project-getProject?project_id=${ id }`,
      this.getProjectDetailSuccess,
      this.getProjectDetailError,
      this.load
    ) 
    getProjectDetailSuccess = payload => this.setState({ projectDetail: payload.data })
    getProjectDetailError = error => this.requestError( error )

    getActivityReports = reports => Get(
      `/activity_report-getActivityReports?reports=${ JSON.stringify( reports ) }&project_id=${ this.state.projectDetail.project_id }`,
      this.getActivityReportsSuccess,
      this.getActivityReportsError,
      this.load
    )
    getActivityReportsSuccess = payload => {
      let temp = _.cloneDeep( payload.data.reports )
      let yearOptions = []
      let summary = {}
      temp.map( report => {
        let month = Moment( report.submited_date ).format( 'MMM' )
        let year = Moment( report.submited_date ).format( 'YYYY' )
        if( yearOptions.includes( year ) ){
          if( summary[ year ] ){
            summary[ year ][ month ] = report
          } else {
            summary[ year ] = {}
            summary[ year ][ month ] = report
          }
        } else {
          yearOptions.push( year )
          summary = {
            [ year ]:{
              [ month ]: report
            } 
          }
        }
      })

      this.setState({ 
        activity_reports: payload.data.reports,
        activity_reports_summary: summary,
        summary_year_options: yearOptions,
      })
    }
    getActivityReportsError = error => this.requestError( error )

    getKPIs = kpis => Get(
      `/kpi-getKPIs?kpis=${ JSON.stringify( kpis ) }`,
      this.getKPIsSuccess,
      this.getKPIsError,
      this.load
    )
    getKPIsSuccess = payload => this.setState({ kpi: payload.data.kpi })
    getKPIsError = error => this.requestError( error )

    getSiteAudit = id => Get(
      `/assessment-getSiteAudit?site_audit_id=${ id }`,
      this.getSiteAuditSuccess,
      this.getSiteAuditError,
      this.load
    )
    getSiteAuditSuccess = payload => this.setState({ siteAudit: payload.data })
    getSiteAuditError = error => this.requestError( error )

    render = () => {
      return (
        <>
          <WrappedComponent
            { ...this.props } 
            { ...this.state }
            onLoadSiteAudit={ this.state.loading }

            getKPIs={ this.getKPIs }
            getSiteAudit={ this.getSiteAudit }
            getProjectDetail={ this.getProjectDetail }
            getActivityReports={ this.getActivityReports }
            onChangeSiteAuditHOC={ this.onChangeSiteAuditHOC }/>
          <CustomSnackbar
            open={ this.state.showSnackbar }
            message={ this.state.snackbarMessage }
            onClick={ this.state.onCloseSnackbar }
            type={ this.state.snackbarType }
          />
        </>
      )
    }
  }
  return WithHOC;
}

export default HOC