import React, { useState } from 'react'
import _ from 'lodash'
import localStorage from 'localStorage'
import { Form } from 'react-bootstrap'
import { Collapse, Select, MenuItem } from '@mui/material'
import { LocationSearching } from '@mui/icons-material'

import { getTranslation } from 'utils/renderLanguage'
import './index.scss'

const language = localStorage.getItem( 'english' )

const SearchForm = props => {

  const [ showSearchField, setShowSearchField ] = useState( false )

  const onClickReset= () => {
    let temp = _.cloneDeep( props.searchParams )
    temp = temp.map( search => { return { ...search, param: '' } })
    props.onChangeProjectManagementHOC( temp, 'searchParams' )
    props.onChangeProjectManagementHOC( props.projectList, 'searchProjectList' ) 
  }

  const onClickSearch= () => {
    let temp = _.cloneDeep( props.projectList )
    props.searchParams.map( search => {
      if( search.param ){
        temp = _.filter( temp, item => item[ search.value ].includes( search.param ) )
      }
    })
    props.onChangeProjectManagementHOC( temp, 'searchProjectList' )
  }

  const onChangeField= ( val, index ) => {
    let temp = _.cloneDeep( props.searchParams )
    temp[ index ].param = val
    props.onChangeProjectManagementHOC( temp, 'searchParams' )
  }

  return (
    <div className='at-card-list__card-cont' style={{ marginBottom: 20 }}>
      <button className='at-search__expand-btn' onClick={ () => setShowSearchField( !showSearchField ) } >
        { getTranslation( language, 'Filter and Search' ) } <LocationSearching/>
      </button>
      <Collapse in={ showSearchField }>
        <div className='row'>
          {
            props.searchParams.map(( search, index ) => (
              <Form.Group className='col-md-4' style={{ marginBottom: 10 }}>
                <Form.Label>{ getTranslation( language, search.label ) }</Form.Label>
                <Form.Control
                  type={ 'text' }
                  value={ search.param }
                  onChange={ e => onChangeField( e.target.value, index ) }
                />
              </Form.Group>
            ))
          }
          <Form.Group className='col-md-4' style={{ marginBottom: 10 }}>
            <Form.Label>{ getTranslation( language, 'Year' ) }</Form.Label>
            <Select
              fullWidth
              value={ props.selected_year || [] }
              style={{ borderRadius: `0.375rem`, borderColor: '#ced4da', height: 50 }}
              onChange={ e => props.onChangeProjectManagementHOC( e.target.value, 'selected_year' ) } >
              {
                props.yearSelection.map(( option, index ) => (
                  <MenuItem key={ index } value={ option } >{ option }</MenuItem>
                ))
              }
            </Select>
          </Form.Group>
        </div>
        <div className='d-flex my-3'>
          <button className='btn btn-danger' onClick={ () => onClickReset() }>{ getTranslation( language, 'Reset' ) }</button>
          <button className='btn btn-primary mx-2' onClick={ () => onClickSearch() }>{ getTranslation( language, 'Search' ) }</button>
        </div>
      </Collapse>
    </div>
  )
}

export default SearchForm;