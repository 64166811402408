import React, { useEffect } from 'react'
import _ from 'lodash'
import localStorage from 'localStorage'
import { useOutletContext, useNavigate } from "react-router-dom";
import { ManageAccounts, EditOutlined, DeleteForeverRounded } from '@mui/icons-material'

import InfoCard from 'components/Card/InfoCard'
import PaginatedTable from 'components/PaginatedTable'
import ModuleHeader from 'components/Card/ModuleHeader'
import DeleteConfirmation from 'components/Card/Confirmation'

import LoadingModal from 'components/LoadingModal'
import CreateUserForm from './form'
import UpdateUserForm from './form'


import UserManagementHOC from './actions'
import { getTranslation } from 'utils/renderLanguage'


const UserManagement = props => {
  const navigate = useNavigate()
  const outletContext = useOutletContext()
  const cardList = [
    { label: getTranslation( outletContext.language, 'Total User' ), value: 'total_user' },
    { label: getTranslation( outletContext.language, 'Total Admin/Secretariat' ), value: 'total_secretariat' },
    { label: getTranslation( outletContext.language, 'Total Assessor' ), value: 'total_assessor' },
    { label: getTranslation( outletContext.language, 'Total Project Leader' ), value: 'total_leader' },
  ]
  
  const columnData = [
    { header: getTranslation( outletContext.language, 'Name' ), accessor: 'display_name' },
    { header: getTranslation( outletContext.language, 'Email' ), accessor: 'email' },
    { header: getTranslation( outletContext.language, 'Mobile No' ), accessor: 'mobile_no' },
    { header: getTranslation( outletContext.language, 'Role' ), accessor: 'role' },
    { header: getTranslation( outletContext.language, 'Status' ), accessor: 'status' },
  ]

  useEffect( () => {
    props.getUsers()
    outletContext.getUserDictionary()
    outletContext.getProjectDictionary()
  }, [] )

  return(
    <>
      <ModuleHeader 
        title={ getTranslation( outletContext.language, 'User Management' ) }
        description={ getTranslation( outletContext.language, 'This module use to manage all the user in the system.' ) }
        actionButton={[{
          label: getTranslation( outletContext.language, 'Create User' ),
          className: 'btn btn-primary',
          onClick: () => props.onChangeUserManagementHOC( true, 'showCreateModal' )
        }]}
        renderIcon={ () => <ManageAccounts /> }
      />
      <InfoCard
        cardList={ cardList }
        dataValue={ props.dataValue }
      />
      <PaginatedTable
        rowData={ props.userList }
        columnData={ columnData }
        actionsColumn={[{
          name: 'Edit',
          renderActionIcon: <EditOutlined />,
          onClick: rowData => Promise.all([
            props.onChangeUserManagementHOC( rowData, 'selectedUser' )
          ]).then( () => {
            props.onChangeUserManagementHOC( true, 'showEditModal' )
          })
        }, {
          name: 'Delete',
          style: { background: '#cb1515' },
          renderActionIcon: <DeleteForeverRounded />,
          onClick: rowData => Promise.all([
            props.onChangeUserManagementHOC( rowData, 'selectedUser' )
          ]).then( () => {
            props.onChangeUserManagementHOC( true, 'showDeleteModal' )
          })
        }]}
      />
      {
        props.showCreateModal && <CreateUserForm
          mode={ 'create' }
          title={ getTranslation( outletContext.language, 'Create New User' ) }
          language={ outletContext.language }
          createNewUser={ props.createNewUser }
          projectDictionary={ outletContext.projectDictionary  }
          setOpen={ val => props.onChangeUserManagementHOC( val, 'showCreateModal' ) }
        />
      }
      {
        props.showEditModal && <UpdateUserForm
          mode={ 'update' }
          title={ getTranslation( outletContext.language, 'Update User' ) }
          language={ outletContext.language }
          selectedUser={ props.selectedUser }
          updateUser={ props.updateUser }
          projectDictionary={ outletContext.projectDictionary  }
          setOpen={ val => props.onChangeUserManagementHOC( val, 'showEditModal' ) }
        />
      }
      {
        props.showDeleteModal && <DeleteConfirmation
          isOpen={ true }
          onClick={ () => props.deleteUser( props.selectedUser.uid ) }
          confirmationText={
            <>
              { getTranslation(  outletContext.language , 'You are going to remove the selected user ' ) }<b>({ props.selectedUser.display_name })</b>{ getTranslation(  outletContext.language , ' from the system and this will also remove the user from all related project.' ) }
              <br/><br/>
              <b className='text-danger'>{ getTranslation(  outletContext.language , 'This action is non-reversible.' ) }</b>
            </>
          }
          setOpen={ val => props.onChangeUserManagementHOC( val, 'showDeleteModal' ) }
        />
      }
      {
        props.onLoadUserManagement && <LoadingModal />
      }
    </>
  )
}

export default UserManagementHOC( UserManagement );