import React, { useEffect } from 'react'
import localStorage from 'localStorage'
import { useOutletContext } from 'react-router-dom';
import { Info, Add } from '@mui/icons-material';

import LoadingModal from 'components/LoadingModal'
import PaginatedTable from 'components/PaginatedTable'

import { getTranslation } from 'utils/renderLanguage'
import { assessmentStatus } from 'utils/stageStatus'
import SiteAssessmentHOC from './actions'

const language = localStorage.getItem( 'english' )

const columnData = [
  { header: getTranslation( language, 'Assessor' ), accessor: 'assessor_name' },
  { header: getTranslation( language, 'Quarter' ), accessor: 'date' },
  { header: getTranslation( language, 'Submission Date' ), accessor: 'summission_date' },
  { header: getTranslation( language, 'Rating' ), renderColumn: rowData => (
    <p className='badge__status' style={{ width: 'fit-content', ...assessmentStatus( rowData.status ) }}>
      { getTranslation( language, rowData.status ) }
    </p>
  )}
]

const SiteAuditAssessment = props => {

  const outletContext = useOutletContext()
  const { uid, role } = outletContext.user

  useEffect( () => {
    if( role === 'Assessor' ){
      props.getSiteAssessment( uid, props.projectDetail.project_id )
    } else {
      props.getAllSiteAssessment( props.projectDetail.project_id )
    }
  }, [ props.projectDetail ])

  return(
    <>
      <div className='d-flex justify-content-between mb-2'>
        <label className='at-project-mngmt__form-label'>{ getTranslation( language, 'Site Audit Assessment' ) }</label>
        {
          role === 'Assessor' && (
            <button
              style={{ width: 300 }}
              disabled={ props.this_month_submitted }
              className='btn btn-primary'
              onClick={ () => outletContext.onNavigate( `/dashboard/secretariat/site_audit/${ props.projectDetail.project_id }` ) }>
              <Add style={{ marginRight: 5 }}/>{ getTranslation( language, 'Create Site Audit' ) }
            </button>
          )
        }
      </div>
      <PaginatedTable
        rowData={ props.siteAssessmentList || [] }
        columnData={ columnData }
        actionsColumn={[{
          name: getTranslation( language, 'View' ),
          renderActionIcon: <Info />,
          onClick: rowData => outletContext.onNavigate( `/dashboard/site-audit/${ props.projectDetail.project_id }/${ rowData.site_audit_id }` )
        }]}
      />
      { props.onLoadSiteAssessment && <LoadingModal /> }
    </>
  )
}

export default SiteAssessmentHOC( SiteAuditAssessment );